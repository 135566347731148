import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { useMsal } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

// import * as signalR from "@microsoft/signalr";

import paginate from "jw-paginate";

import { Pagination } from "@fluentui/react-experiments/lib/Pagination";

import CountUp from "react-countup";

import {
  //TextField,
  //DetailsList,
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  //Selection,
  SelectionMode,
  //IColumn,
  mergeStyleSets,
  TooltipHost,
  //Fabric,
  // ActionButton,
  // Icon,
  //ProgressIndicator,
  //Label,
  // Spinner,
  // SpinnerSize,
  CommandBar,
  Stack,
  DocumentCard,
  //DocumentCardActivity,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  //DocumentCardStatus,
  DocumentCardType,
  Text,
  DefaultButton,
  ContextualMenu,
  // ContextualMenuItemType,
  SearchBox,
  PrimaryButton,
  Pivot,
  PivotItem,
  Link,
  MessageBar,
  MessageBarType,
  //Pagination
  //initializeIcons
} from "@fluentui/react";
/* 
import {
    getFileTypeIconProps,
    //FileIconType
} from "@uifabric/file-type-icons";
 */
// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const homeStyles = {
  //width: '208',
  //height: '10vh',
  //width: 185,
  //height: 70,
  //boxSizing: 'border-box',
  //border: '1px solid #eee',
  //overflowY: 'auto',
  //paddingTop: '10vh',
  //background: '#FFFFFF',
  //background: '#106ebe',
  border: 0,
  /* paddingTop: '2%',
  paddingLeft: '2%',
  paddingRight: '2%', */
  margin: 0,
  //minHeight: '100vh',
  width: "100%",
  background: "#FAF9F8",
};

//ProgressIndicator
//const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };
//Progress Indicator Setting
//const intervalDelay = 100;
//const intervalIncrement = 0.01;

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  exampleToggle: {
    display: "inline-block",
    marginBottom: "10px",
    marginRight: "30px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
});
/* 
const shimmeredDetailsListProps = {
renderedWindowsAhead: 0,
renderedWindowsBehind: 0,
};
*/

const cardStyles = {
  cardStyles: {
    root: {
      background: "white",
      //padding: 20,
      //marginRight: '50%',
      //marginRight: '40px',
      //borderTop: '5px solid #0078d4',
      borderLeft: "5px solid #0078d4",
      width: "30%",
      minWidth: "315px",
      //maxWidth: '415px',
      maxWidth: "424px",
      height: "130px",
      //margin: 'auto',
      //padding: '0 10% 0 10% ',
      //display: 'flex',
      //alignItems: 'center',
      //justifyContent: 'center',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: "bold",
      //paddingTop: '5%',
      paddingTop: "20px",
      textAlign: "center",
      //color: '#696969'
    },
  },
  amount: {
    root: {
      fontSize: 30,
      //paddingBottom: 20,
      //paddingBottom: '8%',
      paddingBottom: "25px",
      //paddingTop: '20%',
      //paddingRight: '20%',
      textAlign: "center",
    },
  },
};

function formatDateTime(date) {
  //let d = new Date(date).toLocaleString("en-GB", {timeZone: "Asia/Bangkok"}),
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes(),
    second = "" + d.getSeconds();

  //console.log(d.toString());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  if (second.length < 2) second = "0" + second;

  return [year, month, day, hour, minute, second].join("");
}

const SubscriptionAccounts = (props) => {
  console.log("Home render!!!");

  const history = useHistory();

  const { instance, accounts } = useMsal();
  //const account = useAccount(accounts[0] || {});
  const account = instance.getAllAccounts()[0];

  //let token = b2cauth.getAccessToken();
  //console.log('Bearer', token);

  //const token = props.token;
  //const token = props.token;
  //

  //const api_key = props.api_key;
  //console.log('Api-Key:', props.api_key);

  //const items = [];
  const [subscriptions, setSubscriptions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  // const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
  //const [clickDelete, setClickDelete] = useState(false);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(
    sessionStorage.getItem('accounts-pageSize') * 1 || 10
  );
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem('accounts-currentPage') * 1 || 1
  );
  /* 
  const [pageData, setPageData] = useState({
    totalItems: '',
    currentPage: '',
    pageSize: '',
    totalPages: '',
    startPage: '',
    endPage: '',
    startIndex: '',
    endIndex: '',
    pages: [] 
  });
*/
  //const [percentComplete, setPercentComplete] = useState(0);

  //CommandBars Items
  const [billingsType, setBillingsType] = useState({
    key: "subscription",
    text: "บัญชี Subscription",
  });
  const [enabled, setEnabled] = useState({
    key: "enabled-all",
    text: "สถานะทั้งหมด",
  });
  const [digitalSign, setDigitalSign] = useState({
    key: "digitalSign-all",
    text: "ทั้งหมด",
  });
  const [attachXml, setAttachXml] = useState({
    key: "attachXml-all",
    text: "ทั้งหมด",
  });
  const [pdfa, setPdfa] = useState({
    key: "pdfa-all",
    text: "ทั้งหมด",
  });

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalPaidAccounts, setTotalPaidAccounts] = useState(0);
  // const [income, setIncome] = useState(0);
  const [topupDocumentsCredits, setTopupDocumentsCredits] = useState(0);
  const [search, setSearch] = useState("");
  // const [filterDocuments, setFilterDocuments] = useState([]);

  const [countTotal, setCountTotal] = useState(0);
  const [countThisMonthTotal, setCountThisMonthTotal] = useState(0);
  const [countLastMonthTotal, setCountLastMonthTotal] = useState(0);

  const [pageDropdown, setPageDropdown] = useState([]);

  const [copySuccess, setCopySuccess] = useState(false);


  const getMonth = (goBack) => {
    let monthNames = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];

    let d = new Date();
    //console.log('new Date(): ', d);

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    //d.setMonth(d.getMonth() - goBack);
    //console.log(d.toLocaleDateString());
    //console.log(d.getMonth());

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + " " + year,
    };
    //return monthNames[d.getMonth()] + ' ' + year;
    //let m = d.getMonth();
    /* 
  let d;
  let year;
  let _month = [];
  

  for (let i = 0; i < 6; i++) {
    d = new Date();
    d.setMonth(d.getMonth() - i);
    year = d.getFullYear() + 543;
    _month.push(monthNames[d.getMonth()] + ' ' + year);
  }
*/

    //console.log(_month);

    //return _month;
  };
  /* 
      const [queryMonth, setQueryMonth] = useState(
          (sessionStorage.getItem("accounts-queryMonth-key") && {
              key: sessionStorage.getItem("accounts-queryMonth-key"),
              text: sessionStorage.getItem("accounts-queryMonth-text"),
          }) || { key: getMonth(0).year + "" + getMonth(0).key, text: "เดือนนี้" }
      );
   */
  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("accounts-queryMonth-key") && {
      key: sessionStorage.getItem("accounts-queryMonth-key"),
      text: sessionStorage.getItem("accounts-queryMonth-text"),
    }) || { key: "all-month", text: "ทั้งหมด" }
  );

  const getFrom = (month, year) => {
    //let date = new Date();
    let _firstDay = new Date(year, month, 1);
    //let _lastDay = new Date(year, month + 1, 1);
    console.log("First day: ", formatDate(_firstDay));

    return formatDate(_firstDay);
    //setLastDay(formatDate(_lastDay));

    //console.log('Last day: ', formatDate(_lastDay));
  };

  const getTo = (month, year) => {
    //let date = new Date();
    //let _firstDay = new Date(year, month, 1);
    let _lastDay = new Date(year, month + 1, 1);
    console.log("Last day: ", formatDate(_lastDay));

    return formatDate(_lastDay);
    //setLastDay(formatDate(_lastDay));

    //console.log('Fist day: ', formatDate(_firstDay));
  };

  const formatDate = (d) => {
    //let d = new Date(date),
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  function convertDateToUTC() {
    var date = new Date();
    var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    return new Date(now_utc).toISOString();
  }

  const [from, setFrom] = useState("2020-01-01");
  // const [from, setFrom] = useState((sessionStorage.getItem("accounts-from") && sessionStorage.getItem("accounts-from")) || getFrom(getMonth(0).month, getMonth(0).year));
  //const [from, setFrom] = useState('2021-07-01');
  const [to, setTo] = useState(getTo(getMonth(0).month, getMonth(0).year));
  // const [to, setTo] = useState((sessionStorage.getItem("accounts-from") && sessionStorage.getItem("accounts-to")) || getTo(getMonth(0).month, getMonth(0).year));
  //const [to, setTo] = useState('2021-08-01');

  const commandBarItems = [
    {
      key: billingsType.key,
      text: billingsType.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Contact" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: "billingsType-all",
            text: "บัญชีทั้งหมด",
            iconProps: { iconName: "Contact" },
            onClick: () => {
              console.log("บัญชีทั้งหมด");
              setBillingsType({
                key: "billingsType-all",
                text: "บัญชีทั้งหมด",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "subscription",
            text: "บัญชี Subscription",
            iconProps: { iconName: "Contact" },
            onClick: () => {
              console.log("บัญชี Subscription");
              setBillingsType({
                key: "subscription",
                text: "บัญชี Subscription",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "prepaid",
            text: "บัญชี Prepaid",
            iconProps: { iconName: "Contact" },
            onClick: () => {
              console.log("บัญชี Prepaid");
              setBillingsType({
                key: "prepaid",
                text: "บัญชี Prepaid",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
        ],
      },
    },
    {
      key: enabled.key,
      text: enabled.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Contact" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: "enabled-all",
            text: "สถานะทั้งหมด",
            iconProps: { iconName: "Contact" },
            onClick: () => {
              console.log("สถานะทั้งหมด");
              setEnabled({
                key: "enabled-all",
                text: "สถานะทั้งหมด",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "true",
            text: "สถานะเปิดใช้งาน",
            iconProps: { iconName: "Contact" },
            onClick: () => {
              console.log("สถานะเปิดใช้งาน");
              setEnabled({
                key: "true",
                text: "สถานะเปิดใช้งาน",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "false",
            text: "สถานะปิดใช้งาน",
            iconProps: { iconName: "Contact" },
            onClick: () => {
              console.log("สถานะปิดใช้งาน");
              setEnabled({
                key: "false",
                text: "สถานะปิดใช้งาน",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
        ],
      },
    },
    {
      key: digitalSign.key,
      text: digitalSign.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: "digitalSign-all",
            text: "ทั้งหมด",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("ทั้งหมด");
              setDigitalSign({
                key: "digitalSign-all",
                text: "ทั้งหมด",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "true",
            text: "e-Tax",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("e-Tax");
              setDigitalSign({
                key: "true",
                text: "e-Tax",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "false",
            text: "กระดาษ",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("กระดาษ");
              setDigitalSign({
                key: "false",
                text: "กระดาษ",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
        ],
      },
    },
    {
      key: attachXml.key,
      text: attachXml.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Attach" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: "attachXml-all",
            text: "ทั้งหมด",
            iconProps: { iconName: "Attach" },
            onClick: () => {
              console.log("ทั้งหมด");
              setAttachXml({
                key: "attachXml-all",
                text: "ทั้งหมด",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "true",
            text: "แนบไฟล์ XML",
            iconProps: { iconName: "Attach" },
            onClick: () => {
              console.log("แนบไฟล์ XML");
              setAttachXml({
                key: "true",
                text: "แนบไฟล์ XML",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "false",
            text: "ไม่แนบไฟล์ XML",
            iconProps: { iconName: "Attach" },
            onClick: () => {
              console.log("ไม่แนบไฟล์ XML");
              setAttachXml({
                key: "false",
                text: "ไม่แนบไฟล์ XML",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
        ],
      },
    },
    {
      key: pdfa.key,
      text: pdfa.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "PDF" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: "pdfa-all",
            text: "ทั้งหมด",
            iconProps: { iconName: "PDF" },
            onClick: () => {
              console.log("ทั้งหมด");
              setPdfa({
                key: "pdfa-all",
                text: "ทั้งหมด",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "true",
            text: "เปิด PDFA",
            iconProps: { iconName: "PDF" },
            onClick: () => {
              console.log("เปิด PDFA");
              setPdfa({
                key: "true",
                text: "เปิด PDFA",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: "false",
            text: "ปิด PDFA",
            iconProps: { iconName: "PDF" },
            onClick: () => {
              console.log("ปิด PDFA");
              setPdfa({
                key: "false",
                text: "ปิด PDFA",
              });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);

              setCurrentPage(1);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: queryMonth.text,
      iconProps: { iconName: "Calendar" },
      disabled: !loadDataComplete,
      //href: 'https://developer.microsoft.com/en-us/fluentui',
      subMenuProps: {
        items: [
          {
            key: "all-month",
            text: "ทั้งหมด",
            iconProps: { iconName: "Calendar" },
            onClick: () => {
              //console.log(getMonth(5).key);
              setQueryMonth({
                key: "all-month",
                text: "ทั้งหมด",
              });
              sessionStorage.setItem("accounts-queryMonth-key", "all-month");
              sessionStorage.setItem("accounts-queryMonth-text", "ทั้งหมด");
              //setFirstLastDay(getMonth(5).month, getMonth(5).year);
              setFrom("2020-01-01");
              setTo(getTo(getMonth(0).month, getMonth(0).year));
              sessionStorage.setItem("accounts-from", "2020-01-01");
              sessionStorage.setItem("accounts-to", getTo(getMonth(0).month, getMonth(0).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: getMonth(0).year + "" + getMonth(0).key,
            text: getMonth(0).text,
            iconProps: { iconName: "Calendar" },
            onClick: () => {
              console.log("Selected month: ", getMonth(0).key);
              setQueryMonth({
                key: getMonth(0).year + "" + getMonth(0).key,
                text: getMonth(0).text,
              });
              sessionStorage.setItem("accounts-queryMonth-key", getMonth(0).year + "" + getMonth(0).key);
              sessionStorage.setItem("accounts-queryMonth-text", getMonth(0).text);
              //setFirstLastDay(getMonth(0).month, getMonth(0).year);
              setFrom(getFrom(getMonth(0).month, getMonth(0).year));
              setTo(getTo(getMonth(0).month, getMonth(0).year));
              sessionStorage.setItem("accounts-from", getFrom(getMonth(0).month, getMonth(0).year));
              sessionStorage.setItem("accounts-to", getTo(getMonth(0).month, getMonth(0).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: getMonth(1).year + "" + getMonth(1).key,
            text: getMonth(1).text,
            iconProps: { iconName: "Calendar" },
            onClick: () => {
              console.log("Selected month: ", getMonth(1).key);
              setQueryMonth({
                key: getMonth(1).year + "" + getMonth(1).key,
                text: getMonth(1).text,
              });
              sessionStorage.setItem("accounts-queryMonth-key", getMonth(1).year + "" + getMonth(1).key);
              sessionStorage.setItem("accounts-queryMonth-text", getMonth(1).text);
              //setFirstLastDay(getMonth(1).month, getMonth(1).year);
              setFrom(getFrom(getMonth(1).month, getMonth(1).year));
              setTo(getTo(getMonth(1).month, getMonth(1).year));
              sessionStorage.setItem("accounts-from", getFrom(getMonth(1).month, getMonth(1).year));
              sessionStorage.setItem("accounts-to", getTo(getMonth(1).month, getMonth(1).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: getMonth(2).year + "" + getMonth(2).key,
            text: getMonth(2).text,
            iconProps: { iconName: "Calendar" },
            onClick: () => {
              console.log("Selected month: ", getMonth(2).key);
              setQueryMonth({
                key: getMonth(2).year + "" + getMonth(2).key,
                text: getMonth(2).text,
              });
              sessionStorage.setItem("accounts-queryMonth-key", getMonth(2).year + "" + getMonth(2).key);
              sessionStorage.setItem("accounts-queryMonth-text", getMonth(2).text);
              //setFirstLastDay(getMonth(2).month, getMonth(2).year);
              setFrom(getFrom(getMonth(2).month, getMonth(2).year));
              setTo(getTo(getMonth(2).month, getMonth(2).year));
              sessionStorage.setItem("accounts-from", getFrom(getMonth(2).month, getMonth(2).year));
              sessionStorage.setItem("accounts-to", getTo(getMonth(2).month, getMonth(2).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: getMonth(3).year + "" + getMonth(3).key,
            text: getMonth(3).text,
            iconProps: { iconName: "Calendar" },
            onClick: () => {
              console.log("Selected month: ", getMonth(3).key);
              setQueryMonth({
                key: getMonth(3).year + "" + getMonth(3).key,
                text: getMonth(3).text,
              });
              sessionStorage.setItem("accounts-queryMonth-key", getMonth(3).year + "" + getMonth(3).key);
              sessionStorage.setItem("accounts-queryMonth-text", getMonth(3).text);
              //setFirstLastDay(getMonth(3).month, getMonth(3).year);
              setFrom(getFrom(getMonth(3).month, getMonth(3).year));
              setTo(getTo(getMonth(3).month, getMonth(3).year));
              sessionStorage.setItem("accounts-from", getFrom(getMonth(3).month, getMonth(3).year));
              sessionStorage.setItem("accounts-to", getTo(getMonth(3).month, getMonth(3).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: getMonth(4).year + "" + getMonth(4).key,
            text: getMonth(4).text,
            iconProps: { iconName: "Calendar" },
            onClick: () => {
              console.log("Selected month: ", getMonth(4).key);
              setQueryMonth({
                key: getMonth(4).year + "" + getMonth(4).key,
                text: getMonth(4).text,
              });
              sessionStorage.setItem("accounts-queryMonth-key", getMonth(4).year + "" + getMonth(4).key);
              sessionStorage.setItem("accounts-queryMonth-text", getMonth(4).text);
              //setFirstLastDay(getMonth(4).month, getMonth(4).year);
              setFrom(getFrom(getMonth(4).month, getMonth(4).year));
              setTo(getTo(getMonth(4).month, getMonth(4).year));
              sessionStorage.setItem("accounts-from", getFrom(getMonth(4).month, getMonth(4).year));
              sessionStorage.setItem("accounts-to", getTo(getMonth(4).month, getMonth(4).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },
          {
            key: getMonth(5).year + "" + getMonth(5).key,
            text: getMonth(5).text,
            iconProps: { iconName: "Calendar" },
            onClick: () => {
              console.log(getMonth(5).key);
              setQueryMonth({
                key: getMonth(5).year + "" + getMonth(5).key,
                text: getMonth(5).text,
              });
              sessionStorage.setItem("accounts-queryMonth-key", getMonth(5).year + "" + getMonth(5).key);
              sessionStorage.setItem("accounts-queryMonth-text", getMonth(5).text);
              //setFirstLastDay(getMonth(5).month, getMonth(5).year);
              setFrom(getFrom(getMonth(5).month, getMonth(5).year));
              setTo(getTo(getMonth(5).month, getMonth(5).year));
              sessionStorage.setItem("accounts-from", getFrom(getMonth(5).month, getMonth(5).year));
              sessionStorage.setItem("accounts-to", getTo(getMonth(5).month, getMonth(5).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalPaidAccounts(0);
              setTopupDocumentsCredits(0);
            },
            //['data-automation-id']: 'newEmailButton', // optional
          },

        ],
      },
    },
    {
      key: currentPage,
      text: "หน้า " + currentPage,
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: "refresh",
      text: "รีเฟรช",
      iconProps: { iconName: "Refresh" },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log("Refresh");

        setLoadDataComplete(false);
        setSubscriptions([]);
        setCompanies([]);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalPaidAccounts(0);
        setTopupDocumentsCredits(0);
      },
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = "";
    date = new Date(isoDateTime);

    //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
    /* 
  let monthNames = [
    "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
    "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
    "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
  ];
*/
    let year = date.getFullYear() + 543;
    //let month = monthNames[date.getMonth()];
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let numOfDay = date.getDate().toString().padStart(2, "0");

    let hour = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let second = date.getSeconds().toString().padStart(2, "0");

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  useEffect(() => {
    console.log("userEffect Call!");

    //console.log('UTC now: ', convertDateToUTC());
  }, []);

  useEffect(() => {
    console.log("userEffect getNewDocuments Call!");

    if (getNewDocuments) {
      // let companiesListEndpoint = ADMIN_API_URL + "/companies?from=" + from + "&to=" + to;
      // let companiesListEndpoint = "http://localhost:7071/api/CompaniesCreatedByList?createdByList=";
      let companiesListEndpoint = ADMIN_API_URL + "/companies?createdByList=";
      // let accountsListEndpoint = ADMIN_API_URL + "/accounts?from=" + from + "&to=" + to + "&offset=" + (currentPage - 1) * pageSize + "&limit=" + pageSize;
      let accountsSummaryEndpoint = ADMIN_API_URL + "/accounts/summaries?from=" + from + "&to=" + to;
      // let documentsCompaniesNameEndpoint = ADMIN_API_URL + "/companies/name/" + documentType.key + "/all?from=" + from + "&to=" + to + "&date=" + dateType.key + "&deleted=" + deletedStatus.key + "&rdStatus=" + rdStatusType.key;
      // let companiesDetailsListEndpoint = ADMIN_API_URL + "/companies-details?billingsType=subscription&from=" + from + "&to=" + to;
      // let companiesDetailsListEndpoint = "http://localhost:7071/api/CompaniesDetailsListV2?" +
      let companiesDetailsListEndpoint = ADMIN_API_URL + "/companies-details/v2/?" +
        "from=" +
        from +
        "&to=" +
        to +
        '&offset=' +
        (currentPage - 1) * pageSize +
        '&limit=' +
        pageSize +
        '&billingsType=' +
        billingsType.key +
        '&enabled=' +
        enabled.key +
        '&digitalSign=' +
        digitalSign.key +
        '&attachXml=' +
        attachXml.key +
        '&pdfa=' + 
        pdfa.key;

      if (search) {
        companiesListEndpoint = ADMIN_API_URL + "/companies?from=" + from + "&to=" + to + "&search=" + search;
        // accountsListEndpoint = ADMIN_API_URL + "/accounts?from=" + from + "&to=" + to + "&offset=" + (currentPage - 1) * pageSize + "&limit=" + pageSize + "&search=" + search;
      }

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          // Do something with the tokenResponse
          console.log("Token response: ", tokenResponse);

          axios
            .get(accountsSummaryEndpoint, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .then((response) => {
              console.log('Accounts summary:', response);

              const { countTotal, countThisMonthTotal, countLastMonthTotal } = response.data;

              setCountTotal(countTotal);
              setCountThisMonthTotal(countThisMonthTotal);
              setCountLastMonthTotal(countLastMonthTotal);
            })
            .catch((error) => console.log(error));


          const [responseCompaniesDetails] = await Promise.all([
            axios
              .get(companiesDetailsListEndpoint, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          let _companiesDetails = responseCompaniesDetails.data.data.documents;
          console.log('_companiesDetails:', _companiesDetails);

          let _totalCompaniesDetails = responseCompaniesDetails.data.data.countTotal;

          if (_companiesDetails.length > 0) {

            // Create createdByList
            let createdByList = "";
            for (let j = 0; j < _companiesDetails.length; j++) {

              if (j === 0) {
                createdByList = _companiesDetails[j].createdBy;
              } else {
                createdByList = createdByList + "," + _companiesDetails[j].createdBy;
              }

            }

            console.log("createdByList:", createdByList);

            await axios
              // .get(ADMIN_API_URL + "/companies?from=" + from + "&to=" + to + "&offset=" + (currentPage - 1) * pageSize + "&limit=" + pageSize, {
              .get(companiesListEndpoint + createdByList, {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                },
              })
              .then(
                async (responseCompanies) => {
                  console.log(responseCompanies);
                  console.log("Companise number: ", responseCompanies.data.length);

                  let _companies = responseCompanies.data;

                  if (_companies.length > 0) {
                    setCompanies(_companies);
                  }

                  // let _docs = [];

                  // let _accounts = response.data.data.accounts;
                  // let _totalAccount = _companiesDetails.length;

                  //Page dropdown
                  let _pageDropdown = [];

                  for (let i = 1; i <= Math.ceil(_totalCompaniesDetails / pageSize); i++) {
                    _pageDropdown.push({
                      key: i,
                      text: 'หน้า ' + i,
                      iconProps: { iconName: 'Page' },
                      onClick: () => {
                        console.log('Selected page: ' + i);
                        setCurrentPage(i);

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);
                      },
                    });
                  }

                  setPageDropdown(_pageDropdown);

                  if (currentPage > Math.ceil(_totalCompaniesDetails / pageSize)) {
                    setCurrentPage(1);
                  }

                  //set page
                  setPageData(paginate(_totalCompaniesDetails, currentPage, pageSize, 10));

                  // setTotalDocuments(response.data.length);

                  //set page
                  // console.log('Page data: ', paginate(response.data.length, 1, 5, 10));
                  // setPageData(paginate(response.data.length, currentPage, pageSize, 10));

                  // setDocuments(response.data);

                  let _docs = [];
                  // let _topupDocumentsCredits = 0;

                  if (_companiesDetails.length > 0) {
                    // const _total = _totalAccount;

                    // for (let i = (currentPage-1)*pageSize; i < Math.min(currentPage*pageSize, _accounts.length); i++) {
                    for (let i = 0; i < _companiesDetails.length; i++) {
                      let createdTime = toThaiDateString(_companiesDetails[i].createdTime);

                      let _companiesSelected =
                        _companies.find((item) => {
                          return item.id === _companiesDetails[i].id;
                        }) || "";

                      console.log("Company selected: ", _companiesSelected);

                      let _documentsLastTopupTime = "";
                      let _smsLastTopupTime = "";

                      if (_companiesDetails[i].balances?.documents?.lastTopupTime) {
                          _documentsLastTopupTime = toThaiDateString(_companiesDetails[i].balances?.documents?.lastTopupTime);
                      }

                      if (_companiesDetails[i].balances?.sms?.lastTopupTime) {
                          _documentsLastTopupTime = toThaiDateString(_companiesDetails[i].balances?.sms?.lastTopupTime);
                      }

                      _docs.push({
                        key: _companiesDetails[i].id,
                        number: _totalCompaniesDetails - ((currentPage - 1) * pageSize) - i,
                        createdTime: createdTime,
                        name: _companiesSelected.name,
                        taxId: _companiesSelected.taxId,
                        branchNumber: _companiesSelected.branchNumber,
                        email: _companiesSelected.email,
                        billingsType: _companiesDetails[i].billings.type,
                        subscriptionsFrom: _companiesDetails[i].subscriptions?.from || "",
                        subscriptionsto: _companiesDetails[i].subscriptions?.from || "",
                        subscriptionsPlanName: _companiesDetails[i].subscriptions?.plan?.name || "",
                        createdBy: _companiesDetails[i].createdBy,
                        // firstName: _companiesDetails[i].profiles.firstName,
                        // lastName: _accounts[i].profiles.lastName,
                        // name: _companiesDetails[i].profiles.name,
                        // mobile: _accounts[i].profiles.mobile || "",
                        // oid: _accounts[i].oid,
                        documentsCredits: _companiesDetails[i].balances?.documents?.credits || "",
                        documentsLastTopup: _companiesDetails[i].balances?.documents?.lastTopup || "",
                        documentsLastTopupTime: _documentsLastTopupTime,
                        smsCredits: _companiesDetails[i].balances?.sms?.lastTopupTime || "",
                        smsLastTopup: _companiesDetails[i].balances?.sms?.lastTopupTime || "",
                        smsLastTopupTime: _smsLastTopupTime,
                        // companyName: _companiesSelected.name || "",
                        // companyId: _companiesSelected.id || "",
                        oid: _companiesDetails[i].createdBy,
                        companyName: _companiesSelected.name || "",
                        companyId: _companiesDetails[i].id || "",
                        companiesDetails: _companiesDetails[i],
                      });
                    }
                  }


                  console.log('Docs data', _docs);
                  setItems(_docs);

                  setLoadDataComplete(true);
                  setGetNewDocuments(false);

                },
                (error) => {
                  console.log(error);
                }
              );

          }

          setLoadDataComplete(true);
          setGetNewDocuments(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });
    }
  }, [getNewDocuments]);
  /* 
      useEffect(() => {
          if (documents.length > 0) {
              console.log("documents current page: ", documents);
  
              if (search) {
                  if (filterDocuments.length > 0) {
                      const _docs = [];
  
                      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocuments.length); i++) {
                          _docs.push(filterDocuments[i]);
                      }
                      console.log("Docs data", _docs);
  
                      setItems(_docs);
                      //setLoadDataComplete(true);
                      //setGetNewDocuments(false);
                  }
              } else {
                  //let _subscriptions = subscriptions || [];
  
                  const _docs = [];
  
                  const _total = documents.length;
  
                  for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
                      let createdTime = toThaiDateString(documents[i].createdTime);
  
                   let _companies =
                          companies.find((item) => {
                              return item.createdBy === documents[i].oid;
                          }) || "";
                      
                      _docs.push(documents[i]);
                  }
                  console.log("Docs data", _docs);
  
                  setItems(_docs);
                  //setLoadDataComplete(true);
                  //setGetNewDocuments(false);
              }
          }
      }, [currentPage]);
       */
  const onPageChange = (selectedPageIndex) => {
    console.log("selectedPageIndex: ", selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
    setGetNewDocuments(true);
    setLoadDataComplete(false);
  };

  const columns = [
    {
      key: "column1",
      name: "Number",
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      iconName: "Contact",
      isIconOnly: true,
      fieldName: "name",
      minWidth: 30,
      maxWidth: 30,
      onColumnClick: "",

      onRender: (item) => {
        return <span>{item.number}</span>;
      },
    },
    {
      key: "column2",
      name: "วันที่สร้าง",
      fieldName: "createdTime",
      minWidth: 60,
      maxWidth: 125,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      onColumnClick: "",
      data: "number",
      onRender: (item) => {
        return <span>{item.createdTime}</span>;
      },
    },
    
    {
      key: "column3",
      name: "ชื่อบริษัท",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: false,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        // return <span>{item.firstName}</span>;
        return (
          <TooltipHost content={item.name}>
            <span>{item.name}</span>
          </TooltipHost>
        );
      },
    },
    {
      key: "column4",
      name: "Tax ID",
      fieldName: "taxId",
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      isSorted: false,
      isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return <Link onClick={() => {
          navigator.clipboard.writeText(item.taxId);
          setCopySuccess(true);
          window.scrollTo(0, 0);
        }}>{item.taxId}</Link>;
      },
    },
    {
      key: "column5",
      name: "สาขา",
      fieldName: "branchNumber",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      //isSorted: true,
      //isSortedDescending: true,
      isCollapsible: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        //   return <span>{item.name}</span>;
        return (
          <TooltipHost content={item.branchNumber}>
            <span>{item.branchNumber}</span>
          </TooltipHost>
        );
      },
    },
    {
      key: "column6",
      name: "อีเมล",
      fieldName: "email",
      minWidth: 100,
      maxWidth: 160,
      isResizable: true,
      isSorted: false,
      isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return <Link onClick={() => {
          navigator.clipboard.writeText(item.email);
          setCopySuccess(true);
          window.scrollTo(0, 0);
        }}>{item.email}</Link>;
      },
    },
    {
      key: "column7",
      name: "แพ็กเกจ",
      fieldName: "package",
      minWidth: 100,
      maxWidth: 160,
      isResizable: true,
      isSorted: false,
      isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return (
          <TooltipHost content={item.subscriptionsPlanName}>
            <span>{item.subscriptionsPlanName}</span>
          </TooltipHost>
        );
      },
    },
    {
      key: "column8",
      name: "รอบบิล เริ่ม",
      fieldName: "start",
      minWidth: 100,
      maxWidth: 160,
      isResizable: true,
      isSorted: false,
      isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return (
          <TooltipHost content={item.subscriptionsFrom}>
            <span>{item.subscriptionsFrom}</span>
          </TooltipHost>
        );
      },
    },
    {
      key: "column9",
      name: "รอบบิล ถึง",
      fieldName: "end",
      minWidth: 100,
      maxWidth: 160,
      isResizable: true,
      isSorted: false,
      isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return (
          <TooltipHost content={item.subscriptionsTo}>
            <span>{item.subscriptionsTo}</span>
          </TooltipHost>
        );
      },
    },
    {
      key: "column10",
      name: "cid",
      fieldName: "cid",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isSorted: false,
      isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return <Link onClick={() => {
          navigator.clipboard.writeText(item.key);
          setCopySuccess(true);
          window.scrollTo(0, 0);
        }}>{item.key}</Link>;
      },
    },
    {
      key: "column11",
      name: "oid",
      fieldName: "createdBy",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isSorted: false,
      isSortedDescending: true,
      onColumnClick: "",
      data: "string",
      onRender: (item) => {
        return <Link onClick={() => {
          navigator.clipboard.writeText(item.createdBy);
          setCopySuccess(true);
          window.scrollTo(0, 0);
        }}>{item.createdBy}</Link>;
      },
    },
    {
      key: "column12",
      name: "",
      fieldName: "command",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      //isCollapsible: true,
      data: "string",
      onColumnClick: "",
      onRender: (item) => {
        return (
          <DefaultButton
            text="เลือก"
            //iconProps={{ iconName: 'Add' }}
            menuProps={{
              items: [
                {
                  key: "add-document-credits",
                  text: "เพิ่มเครดิตเอกสาร",
                  iconProps: { iconName: "PageAdd" },
                  onClick: (event) => {
                    history.push({
                      pathname: "/accounts/credits/documents/add",
                      state: item,
                    });
                  },
                  disabled: !Boolean(item.companyId)
                },
                {
                  key: "add-sms-credits",
                  text: "เพิ่มเครดิต SMS",
                  iconProps: { iconName: "CommentAdd" },
                  onClick: (event) => {
                    history.push({
                      pathname: "/accounts/credits/sms/add",
                      state: item,
                    });
                  },
                  disabled: !Boolean(item.companyId)
                },
                {
                  key: "edit-templates",
                  text: "แก้ไขรูปแบบเอกสาร",
                  iconProps: { iconName: "FileTemplate" },
                  onClick: (event) => {
                    history.push({
                      pathname: "/templates/pdf",
                      state: item,
                    });
                  },
                  disabled: !Boolean(item.companyId)
                },
                {
                  key: "add-certificate",
                  text: "เพิ่มใบรับรอง",
                  iconProps: { iconName: "Certificate" },
                  onClick: (event) => {
                    history.push({
                      pathname: "/etax/certificates/add/p12",
                      state: item,
                    });
                  },
                  disabled: !Boolean(item.companyId)
                },
                {
                  key: "select-certificate",
                  text: "เลือกใบรับรอง",
                  iconProps: { iconName: "ContactCardSettings" },
                  onClick: (event) => {
                    history.push({
                      pathname: "/etax/certificates",
                      state: item,
                    });
                  },
                  disabled: !Boolean(item.companyId)
                },
                {
                  key: "setting-xml-deliver",
                  text: "ตั้งค่าการนำส่ง XML",
                  iconProps: { iconName: "BulkUpload" },
                  onClick: (event) => {
                    history.push({
                      pathname: "/etax/xml/deliver",
                      state: item,
                    });
                  },
                  disabled: !Boolean(item.companyId)
                },
                {
                  key: "setting-companies-details",
                  text: "ตั้งค่าบริษัท",
                  iconProps: { iconName: "CityNext2" },
                  onClick: (event) => {
                    history.push({
                      pathname: "/settings/companies-details",
                      state: item,
                    });
                  },
                  disabled: !Boolean(item.companyId)
                },
              ],
              shouldFocusOnMount: true,
              directionalHintFixed: true,
            }}
            // Optional callback to customize menu rendering
            menuAs={(props) => <ContextualMenu {...props} />}
          // Optional callback to do other actions (besides opening the menu) on click
          //onMenuClick={(ev) => { console.log(ev); }}
          // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
          // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
          // persistMenu={true}
          //allowDisabledFocus
          //disabled={(props) => props}
          //checked={(props) => props}
          />
        );
      },
    },
  ];

  const isCompactMode = false;
  //const isModealSelection = false;
  //const annoucedMessage = undefined;

  return (
    <div style={homeStyles}>
      <Stack>
        {copySuccess &&

          <MessageBar

            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => { setCopySuccess(false) }}
          >
            Copy สำเร็จ!
          </MessageBar>
        }
        <center>
          <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>บริษัทที่ใช้งาน</h2>
          {/* <h4>( สำหรับส่งให้กรมสรรพากร )</h4> */}
          {/* <br/> */}
        </center>
{/* 
        <Stack
          horizontal
          horizontalAlign="center"
        
          tokens={{ childrenGap: "30px" }}
        >
          <DocumentCard
            aria-label="เดือนที่แล้ว"
            styles={cardStyles.cardStyles}
            type={DocumentCardType.compact}
          //onClickHref="http://bing.com"
          >
            <DocumentCardLogo {...{ logoIcon: "Contact" }} title="เดือนที่แล้ว" />
            <DocumentCardDetails>
              <DocumentCardTitle
                title="เดือนที่แล้ว"
                //shouldTruncate
                styles={cardStyles.header}
              />
              <Text styles={cardStyles.amount}>
                <CountUp end={countLastMonthTotal} separator="," duration={1} /> บัญชี
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard
            aria-label="เดือนนี้"
            styles={cardStyles.cardStyles}
            type={DocumentCardType.compact}
          //onClickHref="http://bing.com"
          >
            <DocumentCardLogo {...{ logoIcon: "Contact" }} title="เดือนนี้" />
            <DocumentCardDetails>
              <DocumentCardTitle
                title="เดือนนี้"
                //shouldTruncate
                styles={cardStyles.header}
              />
              <Text styles={cardStyles.amount}>
                <CountUp end={countThisMonthTotal} separator="," duration={1} /> บัญชี
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard
            aria-label="รวมทั้งหมด"
            styles={cardStyles.cardStyles}
            type={DocumentCardType.compact}
          //onClickHref="http://bing.com"
          >
            <DocumentCardLogo {...{ logoIcon: "Contact" }} title="รวมทั้งหมด" />
            <DocumentCardDetails>
              <DocumentCardTitle
                title="รวมทั้งหมด"
                //shouldTruncate
                styles={cardStyles.header}
              />
              <Text styles={cardStyles.amount}>
                <CountUp end={countTotal} separator="," duration={1} /> บัญชี
              </Text>
            </DocumentCardDetails>
          </DocumentCard>
        </Stack>
         */}
      </Stack>

      {/* <br /> */}

      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: "98%",
            //minWidth: '1070px',
            maxWidth: "1350px",
          }}
        >
          {/* <Stack horizontal className="ms-bgColor-white" horizontalAlign="space-between"> */}
          {/* <Stack horizontal className="ms-bgColor-white"> */}
          <Stack
            horizontal
            horizontalAlign="start"
            style={{
              backgroundColor: "#FFFFFF",
              // paddingLeft: '22px'
            }}
          >
            <CommandBar
              items={commandBarItems}
              styles={{
                root: {
                  // width: 668,
                  // width: "100%",
                },
              }}
            //overflowItems={_overflowItems}
            //overflowButtonProps={overflowProps}
            //farItems={_farItems}
            //ariaLabel="Use left and right arrow keys to navigate between commands"
            />
{/* 
            <Stack
              horizontal
              horizontalAlign="end"
              styles={{
                root: {
                  height: 44,
                  backgroundColor: "#FFFFFF",
                  width: "100%",
                },
              }}
              tokens={{ childrenGap: "10px" }}
            >
              <SearchBox
                className="ms-borderColor-themePrimary"
                styles={{
                  root: {
                    marginTop: 6,
                    marginLeft: 6,
                    width: 200,
                    fontSize: 13,
                    fontWeight: 0,
                  },
                }}
                disabled={!loadDataComplete}
                // placeholder="เลขที่ออเดอร์ Lazada"
                onChange={(e) => setSearch(e ? e.target.value : "")}
                onClear={() => {
                  setLoadDataComplete(false);
                  setDocuments([]);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setTotalDocuments(0);
                  setTotalPaidAccounts(0);
                  setTopupDocumentsCredits(0);
                }}
                onSearch={() => {
                  setLoadDataComplete(false);
                  setDocuments([]);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setTotalDocuments(0);
                  setTotalPaidAccounts(0);
                  setTopupDocumentsCredits(0);
                }}
                value={search}
              />
              <PrimaryButton
                // iconProps={{ iconName: 'search' }}
                disabled={!loadDataComplete || !search}
                styles={{
                  root: {
                    marginTop: 6,
                    marginRight: 8,
                    width: 90,
                  },
                }}
                text="ค้นหา"
                onClick={() => {
                  setLoadDataComplete(false);
                  setDocuments([]);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setTotalDocuments(0);
                  setTotalPaidAccounts(0);
                  setTopupDocumentsCredits(0);
                }}
              />
            </Stack>
             */}
            {/* </div> */}
          </Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            style={{
              backgroundColor: "#FFFFFF",
              // paddingLeft: '22px'
            }}
          >
            <Pivot
              // disabled={!loadDataComplete || isProcessDocument}
              selectedKey={String(pageSize)}
              onLinkClick={(item) => {
                console.log("Pivot item: ", item.props.itemKey);

                switch (item.props.itemKey) {
                  case "10":
                    console.log("10 เอกสาร/หน้า");
                    setPageSize(10);
                    sessionStorage.setItem('accounts-pageSize', 10);

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setCountTotal(0);
                    setCountThisMonthTotal(0);
                    setCountLastMonthTotal(0);
                    break;
                  case "30":
                    console.log("30 เอกสาร/หน้า");
                    setPageSize(30);
                    sessionStorage.setItem('accounts-pageSize', 30);

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setCountTotal(0);
                    setCountThisMonthTotal(0);
                    setCountLastMonthTotal(0);
                    break;
                  case "50":
                    console.log("50 เอกสาร/หน้า");
                    setPageSize(50);
                    sessionStorage.setItem('accounts-pageSize', 50);

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setCountTotal(0);
                    setCountThisMonthTotal(0);
                    setCountLastMonthTotal(0);
                    break;
                  default:
                }
              }}
            >
              <PivotItem headerText="10 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="10" />
              {/* <PivotItem headerText="20 เอกสาร/หน้า" itemCount={42} itemIcon="Page"/>
                        <PivotItem headerText="30 เอกสาร/หน้า" itemCount={42} itemIcon="Page"/>
                        <PivotItem headerText="40 เอกสาร/หน้า" itemCount={42} itemIcon="Page"/> */}
              <PivotItem headerText="30 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="30" />
              <PivotItem headerText="50 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="50" />
            </Pivot>
          </Stack>
          <ShimmeredDetailsList
            items={items || []}
            enableShimmer={!loadDataComplete}
            compact={isCompactMode}
            columns={columns}
            selectionMode={SelectionMode.none}
            //selectionMode={SelectionMode.multiple}
            //getKey="1"
            //setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          //selection={Selection}
          //selectionPreservedOnEmptyClick={true}
          //onItemInvoked={download}
          //enterModalSelectionOnTouch={true}
          //ariaLabelForSelectionColumn="Toggle selection"
          //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          //checkButtonAriaLabel="Row checkbox"
          //onRenderItemColumn={ this.onRenderItemColumn }
          //listProps={shimmeredDetailsListProps}
          />
        </Stack>
      </Stack>
      {/* </Fabric> */}
      <br />
      <center>
        {/* {!(documents.length === 0 || documents.length < pageSize) && */
          loadDataComplete && (
            <Pagination
              //selectedPageIndex={page}
              selectedPageIndex={currentPage - 1}
              //pageCount={pageCount}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{
                iconName: "DoubleChevronLeft",
              }}
              previousPageIconProps={{ iconName: "ChevronLeft" }}
              nextPageIconProps={{ iconName: "ChevronRight" }}
              lastPageIconProps={{
                iconName: "DoubleChevronRight",
              }}
            />
          )}

        <br />
        <br />
      </center>
    </div>
  );
};

export default SubscriptionAccounts;
