import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";

import { useMsal } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

import paginate from "jw-paginate";

import { Pagination } from "@fluentui/react-experiments/lib/Pagination";

import CountUp from "react-countup";

import {
    DetailsListLayoutMode,
    ShimmeredDetailsList,
    SelectionMode,
    mergeStyleSets,
    TooltipHost,
    CommandBar,
    Stack,
    DocumentCard,
    DocumentCardTitle,
    DocumentCardDetails,
    DocumentCardLogo,
    DocumentCardType,
    Text,
    DefaultButton,
    ContextualMenu,
    SearchBox,
    PrimaryButton,
    Pivot,
    PivotItem,
    Link,
    MessageBar,
    MessageBarType,
} from "@fluentui/react";

const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const homeStyles = {
    //width: '208',
    //height: '10vh',
    //width: 185,
    //height: 70,
    //boxSizing: 'border-box',
    //border: '1px solid #eee',
    //overflowY: 'auto',
    //paddingTop: '10vh',
    //background: '#FFFFFF',
    //background: '#106ebe',
    border: 0,
    /* paddingTop: '2%',
    paddingLeft: '2%',
    paddingRight: '2%', */
    margin: 0,
    //minHeight: '100vh',
    width: "100%",
    background: "#FAF9F8",
};

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: "16px",
    },
    fileIconCell: {
        textAlign: "center",
        selectors: {
            "&:before": {
                content: ".",
                display: "inline-block",
                verticalAlign: "middle",
                height: "100%",
                width: "0px",
                visibility: "hidden",
            },
        },
    },
    fileIconImg: {
        verticalAlign: "middle",
        maxHeight: "16px",
        maxWidth: "16px",
    },
    controlWrapper: {
        display: "flex",
        flexWrap: "wrap",
    },
    exampleToggle: {
        display: "inline-block",
        marginBottom: "10px",
        marginRight: "30px",
    },
    selectionDetails: {
        marginBottom: "20px",
    },
});

const cardStyles = {
    cardStyles: {
        root: {
            background: "white",
            //padding: 20,
            //marginRight: '50%',
            //marginRight: '40px',
            //borderTop: '5px solid #0078d4',
            borderLeft: "5px solid #0078d4",
            width: "30%",
            minWidth: "315px",
            //maxWidth: '415px',
            maxWidth: "424px",
            height: "130px",
            //margin: 'auto',
            //padding: '0 10% 0 10% ',
            //display: 'flex',
            //alignItems: 'center',
            //justifyContent: 'center',
        },
    },
    header: {
        root: {
            fontSize: 20,
            fontWeight: "bold",
            //paddingTop: '5%',
            paddingTop: "20px",
            textAlign: "center",
            //color: '#696969'
        },
    },
    amount: {
        root: {
            fontSize: 30,
            //paddingBottom: 20,
            //paddingBottom: '8%',
            paddingBottom: "25px",
            //paddingTop: '20%',
            //paddingRight: '20%',
            textAlign: "center",
        },
    },
};

const PrepaidAccounts = (props) => {
    console.log("Home render!!!");

    const history = useHistory();

    const { instance, accounts } = useMsal();
    //const account = useAccount(accounts[0] || {});
    const account = instance.getAllAccounts()[0];

    //let token = b2cauth.getAccessToken();
    //console.log('Bearer', token);

    //const token = props.token;
    //const token = props.token;
    //

    //const api_key = props.api_key;
    //console.log('Api-Key:', props.api_key);

    //const items = [];
    // const [subscriptions, setSubscriptions] = useState([]);
    // const [companies, setCompanies] = useState([]);
    // const [documents, setDocuments] = useState([]);
    const [items, setItems] = useState([]);
    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(true);
    // const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
    //const [clickDelete, setClickDelete] = useState(false);
    const [pageData, setPageData] = useState({});
    const [pageSize, setPageSize] = useState(
        sessionStorage.getItem('accounts-pageSize') * 1 || 10
    );
    const [currentPage, setCurrentPage] = useState(
        sessionStorage.getItem('accounts-currentPage') * 1 || 1
    );
    /* 
    const [pageData, setPageData] = useState({
      totalItems: '',
      currentPage: '',
      pageSize: '',
      totalPages: '',
      startPage: '',
      endPage: '',
      startIndex: '',
      endIndex: '',
      pages: [] 
    });
 */
    //const [percentComplete, setPercentComplete] = useState(0);

    //CommandBars Items
    const [accountType, setAccountType] = useState({
        key: "all",
        text: "บัญชีทั้งหมด",
    });

    //Display Summary
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [totalPaidAccounts, setTotalPaidAccounts] = useState(0);
    // const [income, setIncome] = useState(0);
    const [topupDocumentsCredits, setTopupDocumentsCredits] = useState(0);
    const [search, setSearch] = useState("");
    // const [filterDocuments, setFilterDocuments] = useState([]);

    const [countTotal, setCountTotal] = useState(0);
    const [countThisMonthTotal, setCountThisMonthTotal] = useState(0);
    const [countLastMonthTotal, setCountLastMonthTotal] = useState(0);

    const [pageDropdown, setPageDropdown] = useState([]);

    const [copySuccess, setCopySuccess] = useState(false);


    const getMonth = (goBack) => {
        let monthNames = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];

        let d = new Date();
        //console.log('new Date(): ', d);

        let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

        //d.setMonth(d.getMonth() - goBack);
        //console.log(d.toLocaleDateString());
        //console.log(d.getMonth());

        let year = adjustDate.getFullYear() + 543;

        return {
            month: adjustDate.getMonth(),
            year: adjustDate.getFullYear(),
            key: adjustDate.getMonth() + 1,
            text: monthNames[adjustDate.getMonth()] + " " + year,
        };
        //return monthNames[d.getMonth()] + ' ' + year;
        //let m = d.getMonth();
        /* 
      let d;
      let year;
      let _month = [];
      

      for (let i = 0; i < 6; i++) {
        d = new Date();
        d.setMonth(d.getMonth() - i);
        year = d.getFullYear() + 543;
        _month.push(monthNames[d.getMonth()] + ' ' + year);
      }
*/

        //console.log(_month);

        //return _month;
    };
/* 
    const [queryMonth, setQueryMonth] = useState(
        (sessionStorage.getItem("accounts-queryMonth-key") && {
            key: sessionStorage.getItem("accounts-queryMonth-key"),
            text: sessionStorage.getItem("accounts-queryMonth-text"),
        }) || { key: getMonth(0).year + "" + getMonth(0).key, text: "เดือนนี้" }
    );
 */
    const [queryMonth, setQueryMonth] = useState(
        (sessionStorage.getItem("accounts-queryMonth-key") && {
            key: sessionStorage.getItem("accounts-queryMonth-key"),
            text: sessionStorage.getItem("accounts-queryMonth-text"),
        }) || { key: "all-month", text: "ทั้งหมด" }
    );

    const getFrom = (month, year) => {
        //let date = new Date();
        let _firstDay = new Date(year, month, 1);
        //let _lastDay = new Date(year, month + 1, 1);
        console.log("First day: ", formatDate(_firstDay));

        return formatDate(_firstDay);
        //setLastDay(formatDate(_lastDay));

        //console.log('Last day: ', formatDate(_lastDay));
    };

    const getTo = (month, year) => {
        //let date = new Date();
        //let _firstDay = new Date(year, month, 1);
        let _lastDay = new Date(year, month + 1, 1);
        console.log("Last day: ", formatDate(_lastDay));

        return formatDate(_lastDay);
        //setLastDay(formatDate(_lastDay));

        //console.log('Fist day: ', formatDate(_firstDay));
    };

    const formatDate = (d) => {
        //let d = new Date(date),
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    function convertDateToUTC() {
        var date = new Date();
        var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

        return new Date(now_utc).toISOString();
    }

    const [from, setFrom] = useState("2020-01-01");
    // const [from, setFrom] = useState((sessionStorage.getItem("accounts-from") && sessionStorage.getItem("accounts-from")) || getFrom(getMonth(0).month, getMonth(0).year));
    //const [from, setFrom] = useState('2021-07-01');
    const [to, setTo] = useState(getTo(getMonth(0).month, getMonth(0).year));
    // const [to, setTo] = useState((sessionStorage.getItem("accounts-from") && sessionStorage.getItem("accounts-to")) || getTo(getMonth(0).month, getMonth(0).year));
    //const [to, setTo] = useState('2021-08-01');

    const commandBarItems = [
        {
            key: accountType.key,
            text: accountType.text,
            cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
            iconProps: { iconName: "Contact" },
            disabled: !loadDataComplete,
            subMenuProps: {
                items: [
                    {
                        key: "all",
                        text: "บัญชีทั้งหมด",
                        iconProps: { iconName: "Contact" },
                        onClick: () => {
                            console.log("บัญชีทั้งหมด");
                            setAccountType({
                                key: "all",
                                text: "บัญชีทั้งหมด",
                            });

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);

                            setCurrentPage(1);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: "paid",
                        text: "บัญชีชำระเงิน",
                        iconProps: { iconName: "Contact" },
                        onClick: () => {
                            console.log("บัญชีชำระเงิน");
                            setAccountType({
                                key: "paid",
                                text: "บัญชีชำระเงิน",
                            });

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);

                            setCurrentPage(1);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: "free",
                        text: "บัญชีใช้งานฟรี",
                        iconProps: { iconName: "Contact" },
                        onClick: () => {
                            console.log("บัญชีใช้งานฟรี");
                            setAccountType({
                                key: "free",
                                text: "บัญชีใช้งานฟรี",
                            });

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);

                            setCurrentPage(1);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                ],
            },
        },
        {
            key: queryMonth.key,
            text: queryMonth.text,
            iconProps: { iconName: "Calendar" },
            disabled: !loadDataComplete,
            //href: 'https://developer.microsoft.com/en-us/fluentui',
            subMenuProps: {
                items: [
                    {
                        key: "all-month",
                        text: "ทั้งหมด",
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            //console.log(getMonth(5).key);
                            setQueryMonth({
                                key: "all-month",
                                text: "ทั้งหมด",
                            });
                            sessionStorage.setItem("accounts-queryMonth-key", "all-month");
                            sessionStorage.setItem("accounts-queryMonth-text", "ทั้งหมด");
                            //setFirstLastDay(getMonth(5).month, getMonth(5).year);
                            setFrom("2020-01-01");
                            setTo(getTo(getMonth(0).month, getMonth(0).year));
                            sessionStorage.setItem("accounts-from", "2020-01-01");
                            sessionStorage.setItem("accounts-to", getTo(getMonth(0).month, getMonth(0).year));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(0).year + "" + getMonth(0).key,
                        text: getMonth(0).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(0).key);
                            setQueryMonth({
                                key: getMonth(0).year + "" + getMonth(0).key,
                                text: getMonth(0).text,
                            });
                            sessionStorage.setItem("accounts-queryMonth-key", getMonth(0).year + "" + getMonth(0).key);
                            sessionStorage.setItem("accounts-queryMonth-text", getMonth(0).text);
                            //setFirstLastDay(getMonth(0).month, getMonth(0).year);
                            setFrom(getFrom(getMonth(0).month, getMonth(0).year));
                            setTo(getTo(getMonth(0).month, getMonth(0).year));
                            sessionStorage.setItem("accounts-from", getFrom(getMonth(0).month, getMonth(0).year));
                            sessionStorage.setItem("accounts-to", getTo(getMonth(0).month, getMonth(0).year));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(1).year + "" + getMonth(1).key,
                        text: getMonth(1).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(1).key);
                            setQueryMonth({
                                key: getMonth(1).year + "" + getMonth(1).key,
                                text: getMonth(1).text,
                            });
                            sessionStorage.setItem("accounts-queryMonth-key", getMonth(1).year + "" + getMonth(1).key);
                            sessionStorage.setItem("accounts-queryMonth-text", getMonth(1).text);
                            //setFirstLastDay(getMonth(1).month, getMonth(1).year);
                            setFrom(getFrom(getMonth(1).month, getMonth(1).year));
                            setTo(getTo(getMonth(1).month, getMonth(1).year));
                            sessionStorage.setItem("accounts-from", getFrom(getMonth(1).month, getMonth(1).year));
                            sessionStorage.setItem("accounts-to", getTo(getMonth(1).month, getMonth(1).year));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(2).year + "" + getMonth(2).key,
                        text: getMonth(2).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(2).key);
                            setQueryMonth({
                                key: getMonth(2).year + "" + getMonth(2).key,
                                text: getMonth(2).text,
                            });
                            sessionStorage.setItem("accounts-queryMonth-key", getMonth(2).year + "" + getMonth(2).key);
                            sessionStorage.setItem("accounts-queryMonth-text", getMonth(2).text);
                            //setFirstLastDay(getMonth(2).month, getMonth(2).year);
                            setFrom(getFrom(getMonth(2).month, getMonth(2).year));
                            setTo(getTo(getMonth(2).month, getMonth(2).year));
                            sessionStorage.setItem("accounts-from", getFrom(getMonth(2).month, getMonth(2).year));
                            sessionStorage.setItem("accounts-to", getTo(getMonth(2).month, getMonth(2).year));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(3).year + "" + getMonth(3).key,
                        text: getMonth(3).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(3).key);
                            setQueryMonth({
                                key: getMonth(3).year + "" + getMonth(3).key,
                                text: getMonth(3).text,
                            });
                            sessionStorage.setItem("accounts-queryMonth-key", getMonth(3).year + "" + getMonth(3).key);
                            sessionStorage.setItem("accounts-queryMonth-text", getMonth(3).text);
                            //setFirstLastDay(getMonth(3).month, getMonth(3).year);
                            setFrom(getFrom(getMonth(3).month, getMonth(3).year));
                            setTo(getTo(getMonth(3).month, getMonth(3).year));
                            sessionStorage.setItem("accounts-from", getFrom(getMonth(3).month, getMonth(3).year));
                            sessionStorage.setItem("accounts-to", getTo(getMonth(3).month, getMonth(3).year));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(4).year + "" + getMonth(4).key,
                        text: getMonth(4).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log("Selected month: ", getMonth(4).key);
                            setQueryMonth({
                                key: getMonth(4).year + "" + getMonth(4).key,
                                text: getMonth(4).text,
                            });
                            sessionStorage.setItem("accounts-queryMonth-key", getMonth(4).year + "" + getMonth(4).key);
                            sessionStorage.setItem("accounts-queryMonth-text", getMonth(4).text);
                            //setFirstLastDay(getMonth(4).month, getMonth(4).year);
                            setFrom(getFrom(getMonth(4).month, getMonth(4).year));
                            setTo(getTo(getMonth(4).month, getMonth(4).year));
                            sessionStorage.setItem("accounts-from", getFrom(getMonth(4).month, getMonth(4).year));
                            sessionStorage.setItem("accounts-to", getTo(getMonth(4).month, getMonth(4).year));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    {
                        key: getMonth(5).year + "" + getMonth(5).key,
                        text: getMonth(5).text,
                        iconProps: { iconName: "Calendar" },
                        onClick: () => {
                            console.log(getMonth(5).key);
                            setQueryMonth({
                                key: getMonth(5).year + "" + getMonth(5).key,
                                text: getMonth(5).text,
                            });
                            sessionStorage.setItem("accounts-queryMonth-key", getMonth(5).year + "" + getMonth(5).key);
                            sessionStorage.setItem("accounts-queryMonth-text", getMonth(5).text);
                            //setFirstLastDay(getMonth(5).month, getMonth(5).year);
                            setFrom(getFrom(getMonth(5).month, getMonth(5).year));
                            setTo(getTo(getMonth(5).month, getMonth(5).year));
                            sessionStorage.setItem("accounts-from", getFrom(getMonth(5).month, getMonth(5).year));
                            sessionStorage.setItem("accounts-to", getTo(getMonth(5).month, getMonth(5).year));

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setTotalDocuments(0);
                            setTotalPaidAccounts(0);
                            setTopupDocumentsCredits(0);
                        },
                        //['data-automation-id']: 'newEmailButton', // optional
                    },
                    
                ],
            },
        },
        {
            key: currentPage,
            text: "หน้า " + currentPage,
            iconProps: { iconName: "Page" },
            disabled: !loadDataComplete,
            subMenuProps: {
                items: pageDropdown,
            },
        },
        {
            key: "refresh",
            text: "รีเฟรช",
            iconProps: { iconName: "Refresh" },
            disabled: !loadDataComplete,
            onClick: () => {
                console.log("Refresh");

                setLoadDataComplete(false);
                // setSubscriptions([]);
                // setCompanies([]);
                // setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalPaidAccounts(0);
                setTopupDocumentsCredits(0);
            },
        },
    ];

    const toThaiDateString = (isoDateTime) => {
        let date = "";
        date = new Date(isoDateTime);

        //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
        /* 
      let monthNames = [
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
        "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
        "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
      ];
 */
        let year = date.getFullYear() + 543;
        //let month = monthNames[date.getMonth()];
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let numOfDay = date.getDate().toString().padStart(2, "0");

        let hour = date.getHours().toString().padStart(2, "0");
        let minutes = date.getMinutes().toString().padStart(2, "0");
        let second = date.getSeconds().toString().padStart(2, "0");

        return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    useEffect(() => {
        console.log("userEffect Call!");

        //console.log('UTC now: ', convertDateToUTC());
    }, []);

    useEffect(() => {
        console.log("userEffect getNewDocuments Call!");

        if (getNewDocuments) {
            // let companiesListEndpoint = ADMIN_API_URL + "/companies?from=" + from + "&to=" + to;
            // let companiesListEndpoint = "http://localhost:7071/api/CompaniesCreatedByList?createdByList=";
            let companiesListEndpoint = ADMIN_API_URL + "/companies?createdByList=";
            let accountsListEndpoint = ADMIN_API_URL + "/accounts?from=" + from + "&to=" + to + "&offset=" + (currentPage - 1) * pageSize + "&limit=" + pageSize;
            let accountsSummaryEndpoint = ADMIN_API_URL + "/accounts/summaries?from=" + from + "&to=" + to;
            // let documentsCompaniesNameEndpoint = ADMIN_API_URL + "/companies/name/" + documentType.key + "/all?from=" + from + "&to=" + to + "&date=" + dateType.key + "&deleted=" + deletedStatus.key + "&rdStatus=" + rdStatusType.key;
            // let companiesDetailsListEndpoint = ADMIN_API_URL + "/companies-details?from=" + from + "&to=" + to;
            // let companiesDetailsListEndpoint = "http://localhost:7071/api/CompaniesDetailsCreatedByList?createdByList=";
            let companiesDetailsListEndpoint = ADMIN_API_URL + "/companies-details?createdByList=";

            if (search) {
                // companiesListEndpoint = ADMIN_API_URL + "/companies?from=" + from + "&to=" + to + "&search=" + search;
                accountsListEndpoint = ADMIN_API_URL + "/accounts?from=" + from + "&to=" + to + "&offset=" + (currentPage - 1) * pageSize + "&limit=" + pageSize + "&search=" + search;
            }

            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then(async (tokenResponse) => {
                    // Do something with the tokenResponse
                    console.log("Token response: ", tokenResponse);

                    axios
                        .get(accountsSummaryEndpoint, {
                            headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                        })
                        .then((response) => {
                            console.log('Accounts summary:', response);

                            const { countTotal, countThisMonthTotal, countLastMonthTotal } = response.data;

                            setCountTotal(countTotal);
                            setCountThisMonthTotal(countThisMonthTotal);
                            setCountLastMonthTotal(countLastMonthTotal);
                        })
                        .catch((error) => console.log(error));


                    

                    await axios
                        // .get(ADMIN_API_URL + "/companies?from=" + from + "&to=" + to + "&offset=" + (currentPage - 1) * pageSize + "&limit=" + pageSize, {
                          .get(accountsListEndpoint, {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                            },
                        })
                        .then(
                            async (response) => {
                                
                              console.log(response);
                              console.log("Accounts number: ", response.data.data.countTotal);

                              let _accounts = response.data.data.accounts;
                              let _totalAccount = response.data.data.countTotal;

                              //Page dropdown
                              let _pageDropdown = [];

                              for (let i = 1; i <= Math.ceil(_totalAccount / pageSize); i++) {
                                _pageDropdown.push({
                                  key: i,
                                  text: 'หน้า ' + i,
                                  iconProps: { iconName: 'Page' },
                                  onClick: () => {
                                    console.log('Selected page: ' + i);
                                    setCurrentPage(i);

                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
                                  },
                                });
                              }

                              setPageDropdown(_pageDropdown);

                              if (currentPage > Math.ceil(_totalAccount / pageSize)) {
                                setCurrentPage(1);
                              }

                              //set page
                              setPageData(paginate(_totalAccount, currentPage, pageSize, 10));

                              // setTotalDocuments(response.data.length);

                              //set page
                              // console.log('Page data: ', paginate(response.data.length, 1, 5, 10));
                              // setPageData(paginate(response.data.length, currentPage, pageSize, 10));

                              // setDocuments(response.data);

                              let _docs = [];
                              let _topupDocumentsCredits = 0;

                              if (_accounts.length > 0) {
                                const _total = _totalAccount;

                                // Create createdByList
                                let createdByList = "";
                                for (let j = 0; j < _accounts.length; j++) {

                                  if (j === 0) {
                                    createdByList = _accounts[j].oid;
                                  } else {
                                    createdByList = createdByList + "," + _accounts[j].oid;
                                  }

                                }

                                console.log("createdByList:", createdByList);
                                // console.log("tokenResponse.accessToken:", tokenResponse.accessToken);


                                // Retrive companies list
                                const [responseCompanies] = await Promise.all([
                                  axios
                                      .get(companiesListEndpoint + createdByList, {
                                          headers: {
                                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                                          },
                                      })
                                      .catch((error) => console.log(error)),
                                ]);

                                let _companies = responseCompanies.data;

                                console.log("companiesListEndpoint:", companiesListEndpoint + createdByList);          
                                console.log("_companies:", _companies);


                                // Retrive companies details list
                                const [responseCompaniesDetails] = await Promise.all([
                                  axios
                                      .get(companiesDetailsListEndpoint + createdByList, {
                                          headers: {
                                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                                          },
                                      })
                                      .catch((error) => console.log(error)),
                                ]);
            
                                let _companiesDetails = responseCompaniesDetails.data;

                                console.log("companiesDetailsListEndpoint:", companiesDetailsListEndpoint + createdByList);          
                                console.log("_companiesDetails:", _companiesDetails);


                                // for (let i = (currentPage-1)*pageSize; i < Math.min(currentPage*pageSize, _accounts.length); i++) {
                                for (let i = 0; i < _accounts.length; i++) {
                                  let createdTime = toThaiDateString(_accounts[i].createdTime);

                                  let _companiesSelected =
                                    _companies.find((item) => {
                                        return item.createdBy === _accounts[i].oid;
                                    }) || "";

                                  
                                  let _companiesDetailsSelected =
                                    _companiesDetails.find((item) => {
                                        return item.id === _companiesSelected.id;
                                    }) || "";

                                  let _documentsLastTopupTime = "";
                                  let _smsLastTopupTime = "";

                                  if (_companiesDetailsSelected.balances?.documents?.lastTopupTime) {
                                      _documentsLastTopupTime = toThaiDateString(_companiesDetailsSelected.balances?.documents?.lastTopupTime);
                                  }

                                  if (_companiesDetailsSelected.balances?.sms?.lastTopupTime) {
                                      _documentsLastTopupTime = toThaiDateString(_companiesDetailsSelected.balances?.sms?.lastTopupTime);
                                  }

                       
                                  _docs.push({
                                    key: _accounts[i].id,
                                    number: _total - ((currentPage - 1) * pageSize) - i,
                                    createdTime: createdTime,
                                    email: _accounts[i].profiles.emails[0],
                                    firstName: _accounts[i].profiles.firstName,
                                    lastName: _accounts[i].profiles.lastName,
                                    name: _accounts[i].profiles.name,
                                    mobile: _accounts[i].profiles.mobile || "",
                                    oid: _accounts[i].oid,
                                    documentsCredits: _companiesDetailsSelected.balances?.documents?.credits || "",
                                    documentsLastTopup: _companiesDetailsSelected.balances?.documents?.lastTopup || "",
                                    documentsLastTopupTime: _documentsLastTopupTime,
                                    smsCredits: _companiesDetailsSelected.balances?.sms?.lastTopupTime || "",
                                    smsLastTopup: _companiesDetailsSelected.balances?.sms?.lastTopupTime || "",
                                    smsLastTopupTime: _smsLastTopupTime,
                                    companyName: _companiesSelected.name || "",
                                    companyId: _companiesSelected.id || "",
                                    companiesDetails: _companiesDetailsSelected,
                                  });
                                }
                              }

                              // console.log("Docs data", _docs);

                              if (accountType.key === "paid") {
                                _docs = _docs.filter((_doc) => {
                                  return _doc.documentsLastTopup !== null;
                                });
                              } else if (accountType.key === "free") {
                                _docs = _docs.filter((_doc) => {
                                  return _doc.documentsLastTopup === null;
                                });
                              }
                      
                              setTopupDocumentsCredits(_topupDocumentsCredits);

                              console.log('Docs data', _docs);
                              setItems(_docs);

                              setLoadDataComplete(true);
                              setGetNewDocuments(false);
                                  
                            },
                            (error) => {
                                console.log(error);
                            }
                        );

                    setLoadDataComplete(true);
                    setGetNewDocuments(false);
                })
                .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    // instance.acquireTokenRedirect(silentRequest);
                });
        }
    }, [getNewDocuments]);

    const onPageChange = (selectedPageIndex) => {
        console.log("selectedPageIndex: ", selectedPageIndex);
        setCurrentPage(selectedPageIndex + 1);
        setGetNewDocuments(true);
        setLoadDataComplete(false);
    };

    const columns = [
        {
            key: "column1",
            name: "Number",
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            iconName: "Contact",
            isIconOnly: true,
            fieldName: "name",
            minWidth: 30,
            maxWidth: 30,
            onColumnClick: "",

            onRender: (item) => {
                return <span>{item.number}</span>;
            },
        },
        {
            key: "column2",
            name: "วันที่สร้าง",
            fieldName: "createdTime",
            minWidth: 60,
            maxWidth: 125,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.createdTime}</span>;
            },
        },
        
        {
            key: "column3",
            name: "อีเมล",
            fieldName: "email",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true,
            onColumnClick: "",
            data: "string",
/* 
            onRender: (item) => {
                //   return <span>{item.email}</span>;
                return (
                    <TooltipHost content={item.email}>
                        <span>{item.email}</span>
                    </TooltipHost>
                );
            },
             */
            onRender: (item) => {
                return <Link onClick={() => {
                    navigator.clipboard.writeText(item.email);
                    setCopySuccess(true);
                    window.scrollTo(0, 0);
                }}>{item.email}</Link>;
            },
        },
        {
            key: "column4",
            name: "ชื่อ",
            fieldName: "firstName",
            minWidth: 60,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                // return <span>{item.firstName}</span>;
                return (
                    <TooltipHost content={item.mobile}>
                        <span>{item.firstName}</span>
                    </TooltipHost>
                );
            },
        },
        {
          key: "column5",
          name: "นามสกุล",
          fieldName: "lastName",
          minWidth: 60,
          maxWidth: 80,
          isRowHeader: true,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: "Sorted A to Z",
          sortDescendingAriaLabel: "Sorted Z to A",
          onColumnClick: "",
          data: "string",
          onRender: (item) => {
              // return <span>{item.firstName}</span>;
              return (
                  <TooltipHost content={item.mobile}>
                      <span>{item.lastName}</span>
                  </TooltipHost>
              );
          },
        },
        {
            key: "column6",
            name: "ชื่อที่แสดง",
            fieldName: "name",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            isCollapsible: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                //   return <span>{item.name}</span>;
                return (
                    <TooltipHost content={item.name}>
                        <span>{item.name}</span>
                    </TooltipHost>
                );
            },
        },
        {
            key: "column7",
            name: "เบอร์โทร",
            fieldName: "mobile",
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            isCollapsible: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return <span>{item.mobile}</span>;
            },
        },
        {
            key: "column8",
            name: "บริษัท",
            fieldName: "companyName",
            minWidth: 100,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                // return <span>{item.companyName}</span>;
                return (
                    <TooltipHost content={item.companyName}>
                        <span>{item.companyName}</span>
                    </TooltipHost>
                );
            },
        },        
        {
            key: "column9",
            name: "เครดิต",
            fieldName: "documentsCredits",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.documentsCredits}</span>;
            },
        },
        {
          key: "column10",
          name: "cid",
          fieldName: "companyId",
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          //isSorted: true,
          //isSortedDescending: true,
          onColumnClick: "",
          data: "string",
          onRender: (item) => {
              return <Link onClick={() => {
                  navigator.clipboard.writeText(item.companyId);
                  setCopySuccess(true);
                  window.scrollTo(0, 0);
              }}>{item.companyId}</Link>;
          },
        },
        {
          key: "column11",
          name: "oid",
          fieldName: "oid",
          minWidth: 50,
          maxWidth: 50,
          isResizable: true,
          isSorted: true,
          isSortedDescending: true,
          onColumnClick: "",
          data: "string",
  /* 
          onRender: (item) => {
              //   return <span>{item.oid}</span>;
              return (
                  <TooltipHost content={item.oid}>
                      <span>{item.oid}</span>
                  </TooltipHost>
              );
          },
  */
          onRender: (item) => {
              return <Link onClick={() => {
                  navigator.clipboard.writeText(item.oid);
                  setCopySuccess(true);
                  window.scrollTo(0, 0);
              }}>{item.oid}</Link>;
          },
        },
/*         
        {
            key: "column11",
            name: "เติมล่าสุด",
            fieldName: "documentsLastTopup",
            minWidth: 30,
            maxWidth: 30,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.documentsLastTopup}</span>;
            },
        },
        {
            key: "column12",
            name: "เวลา",
            fieldName: "documentsLastTopupTime",
            minWidth: 70,
            maxWidth: 125,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.documentsLastTopupTime}>
                        <span>{item.documentsLastTopupTime}</span>
                    </TooltipHost>
                );
            },
        },

        {
            key: "column12",
            name: "SMS",
            fieldName: "smsCredits",
            minWidth: 30,
            maxWidth: 30,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.smsCredits}</span>;
            },
        },
        {
            key: "column13",
            name: "เติมล่าสุด",
            fieldName: "smsLastTopup",
            minWidth: 30,
            maxWidth: 30,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "number",
            onRender: (item) => {
                return <span>{item.smsLastTopup}</span>;
            },
        },
        {
            key: "column14",
            name: "เวลา",
            fieldName: "smsLastTopupTime",
            minWidth: 70,
            maxWidth: 125,
            isResizable: true,
            //isSorted: true,
            //isSortedDescending: true,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return (
                    <TooltipHost content={item.smsLastTopupTime}>
                        <span>{item.smsLastTopupTime}</span>
                    </TooltipHost>
                );
            },
        },
         */
        {
            key: "column15",
            name: "",
            fieldName: "command",
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            //isCollapsible: true,
            data: "string",
            onColumnClick: "",
            onRender: (item) => {
                return (
                    <DefaultButton
                        text="เลือก"
                        //iconProps={{ iconName: 'Add' }}
                        menuProps={{
                            items: [
                                {
                                    key: "add-document-credits",
                                    text: "เพิ่มเครดิตเอกสาร",
                                    iconProps: { iconName: "PageAdd" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname: "/accounts/credits/documents/add",
                                            state: item,
                                        });
                                    },
                                    disabled: !Boolean(item.companyId)
                                },
                                {
                                    key: "add-sms-credits",
                                    text: "เพิ่มเครดิต SMS",
                                    iconProps: { iconName: "CommentAdd" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname: "/accounts/credits/sms/add",
                                            state: item,
                                        });
                                    },
                                    disabled: !Boolean(item.companyId)
                                },
                                {
                                    key: "edit-templates",
                                    text: "แก้ไขรูปแบบเอกสาร",
                                    iconProps: { iconName: "FileTemplate" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname: "/templates/pdf",
                                            state: item,
                                        });
                                    },
                                    disabled: !Boolean(item.companyId)
                                },
                                {
                                    key: "add-certificate",
                                    text: "เพิ่มใบรับรอง",
                                    iconProps: { iconName: "Certificate" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname: "/etax/certificates/add/p12",
                                            state: item,
                                        });
                                    },
                                    disabled: !Boolean(item.companyId)
                                },
                                {
                                    key: "select-certificate",
                                    text: "เลือกใบรับรอง",
                                    iconProps: { iconName: "ContactCardSettings" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname: "/etax/certificates",
                                            state: item,
                                        });
                                    },
                                    disabled: !Boolean(item.companyId)
                                },
                                {
                                    key: "setting-xml-deliver",
                                    text: "ตั้งค่าการนำส่ง XML",
                                    iconProps: { iconName: "BulkUpload" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname: "/etax/xml/deliver",
                                            state: item,
                                        });
                                    },
                                    disabled: !Boolean(item.companyId)
                                },
                                {
                                    key: "setting-companies-details",
                                    text: "ตั้งค่าบริษัท",
                                    iconProps: { iconName: "CityNext2" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname: "/settings/companies-details",
                                            state: item,
                                        });
                                    },
                                    disabled: !Boolean(item.companyId)
                                },
/* 
                                {
                                    key: "update-subscription",
                                    text: "รายละเอียดบัญชี",
                                    iconProps: { iconName: "CityNext2" },
                                    onClick: (event) => {
                                        history.push({
                                            pathname: "/accounts/subscriptions/update-subscription",
                                            state: item,
                                        });
                                    },
                                    disabled: !Boolean(item.companyId)
                                },
                                 */
                            ],
                            shouldFocusOnMount: true,
                            directionalHintFixed: true,
                        }}
                        // Optional callback to customize menu rendering
                        menuAs={(props) => <ContextualMenu {...props} />}
                        // Optional callback to do other actions (besides opening the menu) on click
                        //onMenuClick={(ev) => { console.log(ev); }}
                        // By default, the ContextualMenu is re-created each time it's shown and destroyed when closed.
                        // Uncomment the next line to hide the ContextualMenu but persist it in the DOM instead.
                        // persistMenu={true}
                        //allowDisabledFocus
                        //disabled={(props) => props}
                        //checked={(props) => props}
                    />
                );
            },
        },
    ];

    const isCompactMode = false;
    //const isModealSelection = false;
    //const annoucedMessage = undefined;

    return (
        <div style={homeStyles}>
            <Stack>
            {copySuccess && 
                 
                <MessageBar
                    
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={() => {setCopySuccess(false)}}
                >
                    Copy สำเร็จ!               
                </MessageBar>
            }
                <center>
                    <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>บัญชีผู้ใช้งาน</h2>
                    {/* <h4>( สำหรับส่งให้กรมสรรพากร )</h4> */}
                    {/* <br/> */}
                </center>

                <Stack
                    horizontal
                    horizontalAlign="center"
                    //wrap
                    /* 
                styles={{
                  root: {
                    background: '#FAF9F8',                    
                  } 
                }} 
                tokens={{ childrenGap: '2%' }}
 */
                    tokens={{ childrenGap: "30px" }}
                >
                    <DocumentCard
                        aria-label="เดือนที่แล้ว"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                        //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo {...{ logoIcon: "Contact" }} title="เดือนที่แล้ว" />
                        {/* <div className={conversationTileClass}> */}
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="เดือนที่แล้ว"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            {/* <Text styles={cardStyles.amount}>{totalDocuments} ใบ</Text> */}
                            <Text styles={cardStyles.amount}>
                                <CountUp end={countLastMonthTotal} separator="," duration={1} /> บัญชี
                            </Text>
                            {/* </div>  */}
                        </DocumentCardDetails>
                    </DocumentCard>

                    <DocumentCard
                        aria-label="เดือนนี้"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                        //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo {...{ logoIcon: "Contact" }} title="เดือนนี้" />
                        {/* <div className={conversationTileClass}> */}
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="เดือนนี้"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            {/* <Text styles={cardStyles.amount}>{totalVat.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
                            <Text styles={cardStyles.amount}>
                                <CountUp end={countThisMonthTotal} separator="," duration={1} /> บัญชี
                            </Text>
                            {/* </div>  */}
                        </DocumentCardDetails>
                    </DocumentCard>

                    <DocumentCard
                        aria-label="รวมทั้งหมด"
                        styles={cardStyles.cardStyles}
                        type={DocumentCardType.compact}
                        //onClickHref="http://bing.com"
                    >
                        <DocumentCardLogo {...{ logoIcon: "Contact" }} title="รวมทั้งหมด" />
                        {/* <div className={conversationTileClass}> */}
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                title="รวมทั้งหมด"
                                //shouldTruncate
                                styles={cardStyles.header}
                            />
                            {/* <Text styles={cardStyles.amount}>{totalGrand.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
                            <Text styles={cardStyles.amount}>
                                <CountUp end={countTotal} separator="," duration={1} /> บัญชี
                            </Text>
                            {/* </div>  */}
                        </DocumentCardDetails>
                    </DocumentCard>
                </Stack>
            </Stack>

            <br />

            <Stack horizontal horizontalAlign="center">
                <Stack
                    vertical
                    style={{
                        width: "98%",
                        //minWidth: '1070px',
                        maxWidth: "1350px",
                    }}
                >
                    <Stack horizontal className="ms-bgColor-white" horizontalAlign="space-between">
                        <CommandBar
                            items={commandBarItems}
                            styles={{
                                root: {
                                    width: 668,
                                },
                            }}
                            //overflowItems={_overflowItems}
                            //overflowButtonProps={overflowProps}
                            //farItems={_farItems}
                            //ariaLabel="Use left and right arrow keys to navigate between commands"
                        />
                        
                        <Stack
                            horizontal
                            horizontalAlign="end"
                            styles={{
                                root: {
                                    height: 44,
                                    backgroundColor: "#FFFFFF",
                                    width: "100%",
                                },
                            }}
                            tokens={{ childrenGap: "10px" }}
                        >
                            <SearchBox
                                className="ms-borderColor-themePrimary"
                                styles={{
                                    root: {
                                        marginTop: 6,
                                        marginLeft: 6,
                                        width: 200,
                                        fontSize: 13,
                                        fontWeight: 0,
                                    },
                                }}
                                disabled={!loadDataComplete}
                                // placeholder="เลขที่ออเดอร์ Lazada"
                                onChange={(e) => setSearch(e ? e.target.value : "")}
                                onClear={() => {
                                    setLoadDataComplete(false);
                                    // setDocuments([]);
                                    setItems([]);
                                    setGetNewDocuments(true);

                                    setCurrentPage(1);

                                    setTotalDocuments(0);
                                    setTotalPaidAccounts(0);
                                    setTopupDocumentsCredits(0);
                                }}
                                onSearch={() => {
                                    setLoadDataComplete(false);
                                    // setDocuments([]);
                                    setItems([]);
                                    setGetNewDocuments(true);

                                    setCurrentPage(1);

                                    setTotalDocuments(0);
                                    setTotalPaidAccounts(0);
                                    setTopupDocumentsCredits(0);
                                }}
                                value={search}
                            />
                            <PrimaryButton
                                // iconProps={{ iconName: 'search' }}
                                disabled={!loadDataComplete || !search}
                                styles={{
                                    root: {
                                        marginTop: 6,
                                        marginRight: 8,
                                        width: 90,
                                    },
                                }}
                                text="ค้นหา"
                                onClick={() => {
                                    setLoadDataComplete(false);
                                    // setDocuments([]);
                                    setItems([]);
                                    setGetNewDocuments(true);

                                    setCurrentPage(1);

                                    setTotalDocuments(0);
                                    setTotalPaidAccounts(0);
                                    setTopupDocumentsCredits(0);
                                }}
                            />
                        </Stack>
                        {/* </div> */}
                    </Stack>
                    <Stack
                            horizontal
                            horizontalAlign="center"
                            style={{
                                backgroundColor: "#FFFFFF",
                                // paddingLeft: '22px'
                            }}
                        >
                            <Pivot
                                // disabled={!loadDataComplete || isProcessDocument}
                                selectedKey={String(pageSize)}
                                onLinkClick={(item) => {
                                    console.log("Pivot item: ", item.props.itemKey);

                                    switch (item.props.itemKey) {
                                        case "10":
                                            console.log("10 เอกสาร/หน้า");
                                            setPageSize(10);
                                            sessionStorage.setItem('accounts-pageSize', 10);

                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setCountTotal(0);
                                            setCountThisMonthTotal(0);
                                            setCountLastMonthTotal(0);
                                            break;
                                        case "30":
                                            console.log("30 เอกสาร/หน้า");
                                            setPageSize(30);
                                            sessionStorage.setItem('accounts-pageSize', 30);

                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setCountTotal(0);
                                            setCountThisMonthTotal(0);
                                            setCountLastMonthTotal(0);
                                            break;
                                        case "50":
                                            console.log("50 เอกสาร/หน้า");
                                            setPageSize(50);
                                            sessionStorage.setItem('accounts-pageSize', 50);

                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setCountTotal(0);
                                            setCountThisMonthTotal(0);
                                            setCountLastMonthTotal(0);
                                            break;
                                        default:
                                    }
                                }}
                            >
                                <PivotItem headerText="10 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="10" />
                                {/* <PivotItem headerText="20 เอกสาร/หน้า" itemCount={42} itemIcon="Page"/>
                        <PivotItem headerText="30 เอกสาร/หน้า" itemCount={42} itemIcon="Page"/>
                        <PivotItem headerText="40 เอกสาร/หน้า" itemCount={42} itemIcon="Page"/> */}
                                <PivotItem headerText="30 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="30" />
                                <PivotItem headerText="50 เอกสาร/หน้า" itemIcon="NumberedList" itemKey="50" />
                            </Pivot>
                        </Stack>
                    <ShimmeredDetailsList
                        items={items || []}
                        enableShimmer={!loadDataComplete}
                        compact={isCompactMode}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        //selectionMode={SelectionMode.multiple}
                        //getKey="1"
                        //setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        //selection={Selection}
                        //selectionPreservedOnEmptyClick={true}
                        //onItemInvoked={download}
                        //enterModalSelectionOnTouch={true}
                        //ariaLabelForSelectionColumn="Toggle selection"
                        //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        //checkButtonAriaLabel="Row checkbox"
                        //onRenderItemColumn={ this.onRenderItemColumn }
                        //listProps={shimmeredDetailsListProps}
                    />
                </Stack>
            </Stack>
            {/* </Fabric> */}
            <br />
            <center>
                {/* {!(documents.length === 0 || documents.length < pageSize) && */
                    loadDataComplete && (
                        <Pagination
                            //selectedPageIndex={page}
                            selectedPageIndex={currentPage - 1}
                            //pageCount={pageCount}
                            pageCount={pageData.totalPages}
                            onPageChange={onPageChange}
                            format
                            firstPageIconProps={{
                                iconName: "DoubleChevronLeft",
                            }}
                            previousPageIconProps={{ iconName: "ChevronLeft" }}
                            nextPageIconProps={{ iconName: "ChevronRight" }}
                            lastPageIconProps={{
                                iconName: "DoubleChevronRight",
                            }}
                        />
                    )}

                <br />
                <br />
            </center>
        </div>
    );
};

export default PrepaidAccounts;
