import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

import { useHistory, useLocation } from 'react-router-dom';



import {
  Stack,
  Icon,
  PrimaryButton,
  DefaultButton,
  Spinner,
  getTheme,
  MessageBar,
  MessageBarType,
  Dropdown,
  Separator
} from '@fluentui/react';

import styled from 'styled-components';

// const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const theme = getTheme();

//Dropzone Style

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  //return '#eeeeee';
  //return 'rgb(194, 194, 194)';
  return 'rgb(134, 134, 134)';
}

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;    
    background-color: #ffffff;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-top: 5px;
`;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 24,
    width: 24,
    borderWidth: 4,
    marginTop: 25
  }
}

const spinnerStylesLoading = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const cardStyle = {
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 30,
  background: '#ffffff',
  marginLeft: '0',
  marginTop: '10px',
  boxShadow: theme.effects.elevation4,
};

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
  position: 'relative',
};

const dropdownStyles = {
  dropdown: { width: 250 },
};

const toThaiDateString = (isoDateTime) => {
  let date = "";
  date = new Date(isoDateTime);

  //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
  /* 
let monthNames = [
  "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
  "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
  "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
];
*/
  let year = date.getFullYear() + 543;
  //let month = monthNames[date.getMonth()];
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let numOfDay = date.getDate().toString().padStart(2, "0");

  let hour = date.getHours().toString().padStart(2, "0");
  let minutes = date.getMinutes().toString().padStart(2, "0");
  let second = date.getSeconds().toString().padStart(2, "0");

  return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
};

const SettingsCompaniesDetails = () => {

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();
  const location = useLocation();

  const [data] = useState(location.state);

  const [oid, setOid] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [companiesDetails, setCompaniesDetails] = useState({});

  const billingsTypeList = [
    { key: 'prepaid', text: 'Prepaid' },
    { key: 'subscription', text: 'Subscription' },
  ];

  const enabledList = [
    { key: 'true', text: 'เปิดใช้งาน' },
    { key: 'false', text: 'ปิดใช้งาน' },
    { key: '-', text: '-' },
  ];

  const digitalSignList = [
    { key: 'true', text: 'เอกสาร e-Tax Invoice & e-Receipt' },
    { key: 'false', text: 'เอกสารกระดาษ' },
  ];

  const attachXmlList = [
    { key: 'true', text: 'แนบไฟล์ XML' },
    { key: 'false', text: 'ไม่แนบไฟล์ XML' },
  ];

  const ltvList = [
    { key: 'true', text: 'LTV Enabled' },
    { key: 'false', text: 'LTV Disabled' },
    { key: '-', text: '-' },
  ];

  const digitallySignedByList = [
    { key: 'true', text: 'แสดงข้อความ Digitally signed by' },
    { key: 'false', text: 'ไม่แสดงข้อความ Digitally signed by' },
  ];

  const digitallySignedByBEList = [
    { key: 'true', text: 'แสดงปี พ.ศ. บน Digitally signed by' },
    { key: 'false', text: 'แสดงปี ค.ศ. บน Digitally signed by' },
  ];

  const pdfaList = [
    { key: 'true', text: 'ไฟล์ PDF-A3' },
    { key: 'false', text: 'ไฟล์ PDF ธรรมดา' },
  ];

  const enableSmsList = [
    { key: 'true', text: 'เปิดการส่ง SMS' },
    { key: 'false', text: 'ปิดการส่ง SMS' },
  ];

  const serviceProviderList = [
    { key: 'null', text: 'ปิดการนำส่งผ่าน Service Provider' },
    { key: 'ginkgo-soft', text: 'Ginkgo Soft' },
    { key: 'stream-it-consulting', text: 'Stream IT Consulting' },
  ];

  const [getNewData, setGetNewData] = useState(false);
  const [billingsType, setBillingType] = useState('');
  const [enabled, setEnabled] = useState('-');
  const [accountData, setAccountData] = useState({});
  const [digitalSign, setDigitalSign] = useState('true');
  const [pdfa, setPdfa] = useState('true');
  const [attachXml, setAttachXml] = useState('false');
  const [ltv, setLtv] = useState('false');
  const [digitallySignedBy, setDigitallySignedBy] = useState('true');
  const [digitallySignedByBE, setDigitallySignedByBE] = useState('true');
  const [enableSms, setEnableSms] = useState('false');
  const [serviceProvider, setServiceProvider] = useState(null);



  // const [credits, setCredits] = useState(0);
  // const [topupAmount, setTopupAmount] = useState(0);
  // const [totalAmount, setTotalAmount] = useState(0);
  // const [lastTopup, setLastTopup] = useState(0);
  // const [lastTopupTime, setLastTopupTime] = useState('');

  // const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGetNewData, setIsLoadingGetNewData] = useState(true);
  const [isLoadingSubmitBillingType, setIsLoadingSubmitBillingType] = useState(false);
  const [isLoadingSubmitEnabled, setIsLoadingSubmitEnabled] = useState(false);
  const [isLoadingSubmitDigitalSign, setIsLoadingSubmitDigitalSign] = useState(false);
  const [isLoadingSubmitPdfa, setIsLoadingSubmitPdfa] = useState(false);
  const [isLoadingSubmitAttachXml, setIsLoadingSubmitAttachXml] = useState(false);
  const [isLoadingSubmitLtv, setIsLoadingSubmitLtv] = useState(false);
  const [isLoadingDigitallySignedBy, setIsLoadingDigitallySignedBy] = useState(false);
  const [isLoadingDigitallySignedByBE, setIsLoadingDigitallySignedByBE] = useState(false);
  const [isLoadingSubmitEnableSms, setIsLoadingSubmitEnableSms] = useState(false);
  const [isLoadingSubmitServiceProvider, setIsLoadingSubmitServiceProvider] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {

    console.log('useEffect accountData call!: ');

    console.log('accountData', accountData);

  }, [accountData]);

  useEffect(() => {
    console.log('useEffect getNewData call!');

    console.log('oid: ', oid);
    console.log('companyId: ', companyId);

    if (getNewData) {

      if (oid && companyId) {
        //console.log('useEffect data call! not undefied.');

        console.log('data: ', data);

        accountsOidList(oid);
        companiesDetailsCreatedByList(oid);

      }

      // setGetNewData(false);
      // setIsLoadingGetNewData(false);

    }

  }, [getNewData]);

  useEffect(() => {
    console.log('useEffect data call!: ', data);


    if (data !== undefined) {
      //console.log('useEffect data call! not undefied.');

      console.log('data: ', data);
      setCompanyId(data.companyId);
      setOid(data.oid)

      accountsOidList(data.oid);
      companiesDetailsCreatedByList(data.oid);

      // setGetNewData(true);
      // setIsLoadingGetNewData(true);

    } else {
      history.push({ pathname: '/accounts/prepaid', state: {} });
    }


  }, [data]);

  async function companiesDetailsCreatedByList(oid) {

    // console.log('useEffect call!');

    // let companiesDetailsCreatedByListEndpoint = 'http://localhost:7071/api/CompaniesDetailsCreatedByList?createdByList=' + oid;
    let companiesDetailsCreatedByListEndpoint = ADMIN_API_URL + '/companies-details?createdByList=' + oid;
    console.log("companiesDetailsCreatedByListEndpoint: ", companiesDetailsCreatedByListEndpoint);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        // Do something with the tokenResponse
        console.log("Token response: ", tokenResponse);

        // Retrive companies details list
        const [responseCompaniesDetailsCreatedByList] = await Promise.all([
          axios
            .get(companiesDetailsCreatedByListEndpoint, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .catch((error) => console.log(error)),
        ]);
        console.log("responseCompaniesDetailsCreatedByList: ", responseCompaniesDetailsCreatedByList.data);


        if (responseCompaniesDetailsCreatedByList.data.length > 0) {

          let _companiesDetails = responseCompaniesDetailsCreatedByList.data[0];

          setCompaniesDetails(_companiesDetails);

          if (_companiesDetails?.enabled === true) {
            setEnabled('true');
          } else if (_companiesDetails?.enabled === false) {
            setEnabled('false');
          } else {
            setEnabled('-');
          }

          setBillingType(_companiesDetails?.billings?.type);

          if (_companiesDetails?.settings?.digitalSign === true) {
            setDigitalSign('true');
          } else {
            setDigitalSign('false');
          }

          if (_companiesDetails?.settings?.etax?.pdfa === true) {
            setPdfa('true');
          } else {
            setPdfa('false');
          }

          if (_companiesDetails?.settings?.etax?.attachXml === true) {
            setAttachXml('true');
          } else {
            setAttachXml('false');
          }

          if (_companiesDetails?.settings?.ltv === true) {
            setLtv('true');
          } else if (_companiesDetails?.settings?.ltv === false) {
            setLtv('false');
          } else {
            setLtv('-');
          }

          if (_companiesDetails?.settings?.signature?.visible === true) {
            setDigitallySignedBy('true');
          } else {
            setDigitallySignedBy('false');
          }

          if (_companiesDetails?.settings?.signature?.dateBE === true) {
            setDigitallySignedByBE('true');
          } else {
            setDigitallySignedByBE('false');
          }

          if (_companiesDetails?.settings?.enableSms === true) {
            setEnableSms('true');
          } else {
            setEnableSms('false');
          }

          // console.log("_companiesDetails?.settings?.etax?.serviceProvider?.id:", _companiesDetails?.settings?.etax?.serviceProvider?.id);

          if (_companiesDetails?.settings?.etax?.serviceProvider?.id === 'ginkgo-soft') {
            setServiceProvider('ginkgo-soft');
          } else if (_companiesDetails?.settings?.etax?.serviceProvider?.id === 'stream-it-consulting') {
            setServiceProvider('stream-it-consulting');
          } else {
            setServiceProvider('null');
          }

          // setIsLoading(false);
          setGetNewData(false);
          setIsLoadingGetNewData(false);

        }

      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    

  }

  async function accountsOidList(oid) {

    console.log('useEffect call!');

    // let accountsOidListEndpoint = 'http://localhost:7071/api/AccountsOidList?oidList=' + oid;
    let accountsOidListEndpoint = ADMIN_API_URL + '/accounts?oidList=' + oid;
    console.log("accountsOidListEndpoint: ", accountsOidListEndpoint);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        // Do something with the tokenResponse
        console.log("Token response: ", tokenResponse);

        // Retrive companies details list
        const [responseAccountsOidList] = await Promise.all([
          axios
            .get(accountsOidListEndpoint, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .catch((error) => console.log(error)),
        ]);
        console.log("responseAccountsOidList: ", responseAccountsOidList.data);


        if (responseAccountsOidList.data.length > 0) {
          setAccountData(responseAccountsOidList.data[0]);
        }

      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

  }

  const handleSubmitServiceProvider = (e) => {

    e.preventDefault();

    setIsLoadingSubmitServiceProvider(true);
    setIsLoadingGetNewData(true);

    let _serviceProvider = "null";
    
    if (serviceProvider === "ginkgo-soft") {
      _serviceProvider = "ginkgo-soft";
    } else if (serviceProvider === "stream-it-consulting") {
      _serviceProvider = "stream-it-consulting";
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/settings/etax/serviceProvider/id',
              value: _serviceProvider
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingSubmitServiceProvider(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {
              console.log(error);
              setIsLoadingSubmitServiceProvider(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);
            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  const handleSubmitEnableSms = (e) => {

    e.preventDefault();

    setIsLoadingSubmitEnableSms(true);
    setIsLoadingGetNewData(true);

    let _enableSms = (enableSms === 'true');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/settings/enableSms',
              value: _enableSms
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingSubmitEnableSms(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {
              console.log(error);
              setIsLoadingSubmitEnableSms(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);
            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  const handleDigitallySignedByBE = (e) => {

    e.preventDefault();

    setIsLoadingDigitallySignedByBE(true);
    setIsLoadingGetNewData(true);

    let _digitallySignedByBE = (digitallySignedByBE === 'true');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/settings/signature/dateBE',
              value: _digitallySignedByBE
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingDigitallySignedByBE(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {
              console.log(error);
              setIsLoadingDigitallySignedByBE(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);
            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  const handleDigitallySignedBy = (e) => {

    e.preventDefault();

    setIsLoadingDigitallySignedBy(true);
    setIsLoadingGetNewData(true);

    let _digitallySignedBy = (digitallySignedBy === 'true');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/settings/signature/visible',
              value: _digitallySignedBy
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingDigitallySignedBy(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {
              console.log(error);
              setIsLoadingDigitallySignedBy(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);
            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  const handleSubmitLtv = (e) => {

    e.preventDefault();

    setIsLoadingSubmitLtv(true);
    setIsLoadingGetNewData(true);

    let _ltv = (ltv === 'true');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/settings/ltv',
              value: _ltv
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingSubmitLtv(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {
              console.log(error);
              setIsLoadingSubmitLtv(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);
            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  const handleSubmitAttachXml = (e) => {

    e.preventDefault();

    setIsLoadingSubmitAttachXml(true);
    setIsLoadingGetNewData(true);

    let _attachXml = (attachXml === 'true');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/settings/etax/attachXml',
              value: _attachXml
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingSubmitAttachXml(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {
              console.log(error);
              setIsLoadingSubmitAttachXml(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);
            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  const handleSubmitPdfa = (e) => {

    e.preventDefault();

    setIsLoadingSubmitPdfa(true);
    setIsLoadingGetNewData(true);

    let _pdfa = (pdfa === 'true');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/settings/etax/pdfa',
              value: _pdfa
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingSubmitPdfa(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {
              console.log(error);
              setIsLoadingSubmitPdfa(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);
            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  const handleSubmitDigitalSign = (e) => {

    e.preventDefault();

    setIsLoadingSubmitDigitalSign(true);
    setIsLoadingGetNewData(true);

    let _digitalSign = (digitalSign === 'true');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/settings/digitalSign',
              value: _digitalSign
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingSubmitDigitalSign(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {
              console.log(error);
              setIsLoadingSubmitDigitalSign(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);
            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  

  const handleSubmitฺBillingType = (e) => {

    e.preventDefault();

    setIsLoadingSubmitBillingType(true);
    setIsLoadingGetNewData(true);


    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/billings/type',
              value: billingsType
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingSubmitBillingType(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {

              console.log(error);
              setIsLoadingSubmitBillingType(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);

            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  const handleSubmitEnabled = (e) => {

    e.preventDefault();

    setIsLoadingSubmitEnabled(true);
    setIsLoadingGetNewData(true);

    let _enabled = (enabled === 'true');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .patch(
            ADMIN_API_URL + '/companies-details/' + companyId,
            {
              replace: '/enabled',
              value: _enabled
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              //console.log(response.data.id);
              console.log(response);
              setIsLoadingSubmitEnabled(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowSuccess(true);
              // window.scrollTo(0, 0);

            },
            (error) => {

              console.log(error);
              setIsLoadingSubmitEnabled(false);
              // setIsLoadingGetNewData(false);

              setGetNewData(true);

              setShowError(true);
              // window.scrollTo(0, 0);

            }
          );

      })
      .catch((error) => {

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

      });

  }

  return (
    <>
      <div style={homeStyles}>

        {showSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowSuccess(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            บันทึกสำเร็จ!
          </MessageBar>
        )}
        {showError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowError(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}

          >
            บันทึกไม่สำเร็จ!
          </MessageBar>
        )}


        <Stack horizontal horizontalAlign="center">
          <Stack
            vertical
            style={{
              width: '80%',
              maxWidth: '500px',
            }}>
            <h1 style={{ marginLeft: '0px', marginTop: '27px' }} ><Icon iconName="CityNext2" style={{ color: '#106ebe' }} /> ตั้งค่าบริษัท</h1>
            {/* <h3 style={{ marginTop: '0px', marginBottom: '10px' }} >{data.companyName}</h3> */}
            {/* <br/> */}
            companyId: {data.companyId}
            <br />
            ชื่อบริษัท: {data.companyName}
            <br />
            อีเมล: {data.email}
            <br />
            <br />

            oid: {data.oid}
            <br />
            อีเมลเข้าสู่ระบบ: {accountData.profiles?.email}
            <br />
            ชื่อ: {accountData.profiles?.firstName} {accountData.profiles?.lastName}
            <br />
            เบอร์โทร: {accountData.profiles?.mobile}
            <br />
            <br />
            {/*                 เครดิตเอกสาร: {credits}
                <br/>
                เติมล่าสุด: {lastTopup}
                <br/>
                เวลาเติมล่าสุด: {lastTopupTime}                                
                <br/>
                <br/>
 */}
            {!isLoadingGetNewData && (
              <div style={cardStyle}>
                <Stack >
                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }}>

                    <DefaultButton
                      iconProps={{ iconName: "Refresh" }}
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                      disabled={isLoadingGetNewData}
                      onClick={() => {
                        setGetNewData(true);
                        setIsLoadingGetNewData(true);
                      }}
                      text="รีเฟรช" />

                  </Stack>

                  <Separator />

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="สถานะบัญชี:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={enabledList}
                      selectedKey={enabled}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setEnabled(selectedOption.key);
                      }}
                    />

                    {!isLoadingSubmitEnabled &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleSubmitEnabled}
                        text="บันทึก" />
                    }
                    {isLoadingSubmitEnabled &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>

                  <br />

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }} style={{ marginBottom: "10px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="Billings Type:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={billingsTypeList}
                      selectedKey={billingsType}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setBillingType(selectedOption.key);
                      }}
                    />

                    {!isLoadingSubmitBillingType &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleSubmitฺBillingType}
                        text="บันทึก" />
                    }
                    {isLoadingSubmitBillingType &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>

                  <Separator />


                  {/* <br /> */}

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }} style={{ marginBottom: "10px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="ประเภทเอกสาร:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={digitalSignList}
                      selectedKey={digitalSign}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setDigitalSign(selectedOption.key);
                      }}
                    />

                    {!isLoadingSubmitDigitalSign &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleSubmitDigitalSign}
                        text="บันทึก" />
                    }
                    {isLoadingSubmitDigitalSign &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>

                  <Separator />
                  {/* <br /> */}

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="การสร้างไฟล์ PDF:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={pdfaList}
                      selectedKey={pdfa}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setPdfa(selectedOption.key);
                      }}
                    />

                    {!isLoadingSubmitPdfa &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleSubmitPdfa}
                        text="บันทึก" />
                    }
                    {isLoadingSubmitPdfa &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>

                  <br />

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="การแนบไฟล์ XML ในไฟล์ PDF:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={attachXmlList}
                      selectedKey={attachXml}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setAttachXml(selectedOption.key);
                      }}
                    />

                    {!isLoadingSubmitAttachXml &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleSubmitAttachXml}
                        text="บันทึก" />
                    }
                    {isLoadingSubmitAttachXml &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>

                  <br />

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }} style={{ marginBottom: "10px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="LTV ในไฟล์ PDF:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={ltvList}
                      selectedKey={ltv}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setLtv(selectedOption.key);
                      }}
                    />

                    {!isLoadingSubmitLtv &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleSubmitLtv}
                        text="บันทึก" />
                    }
                    {isLoadingSubmitLtv &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>

                  <Separator />

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="การแสดงข้อความ Digitally signed by:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={digitallySignedByList}
                      selectedKey={digitallySignedBy}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setDigitallySignedBy(selectedOption.key);
                      }}
                    />

                    {!isLoadingDigitallySignedBy &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleDigitallySignedBy}
                        text="บันทึก" />
                    }
                    {isLoadingDigitallySignedBy &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>

                  <br />

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }} style={{ marginBottom: "10px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="รูปแบบปี Digitally signed by:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={digitallySignedByBEList}
                      selectedKey={digitallySignedByBE}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setDigitallySignedByBE(selectedOption.key);
                      }}
                    />

                    {!isLoadingDigitallySignedByBE &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleDigitallySignedByBE}
                        text="บันทึก" />
                    }
                    {isLoadingDigitallySignedByBE &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>

                  <Separator />

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }} style={{ marginBottom: "10px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="การส่งข้อความ SMS:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={enableSmsList}
                      selectedKey={enableSms}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setEnableSms(selectedOption.key);
                      }}
                    />

                    {!isLoadingSubmitEnableSms &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleSubmitEnableSms}
                        text="บันทึก" />
                    }
                    {isLoadingSubmitEnableSms &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>

                  <Separator />

                  {/* <br /> */}

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: "30px" }} style={{ marginBottom: "10px" }}>

                    <Dropdown
                      // placeholder="Select an option"
                      label="Service Provider สำหรับส่งไฟล์ XML:"
                      // style={{ width: "150px" }}
                      styles={dropdownStyles}
                      options={serviceProviderList}
                      selectedKey={serviceProvider}
                      // styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        console.log(selectedOption);
                        setServiceProvider(selectedOption.key);
                      }}
                    />

                    {!isLoadingSubmitEnableSms &&
                      <PrimaryButton
                        style={{ marginTop: "28px" }}
                        onClick={handleSubmitServiceProvider}
                        text="บันทึก" />
                    }
                    {isLoadingSubmitEnableSms &&
                      <Spinner /* size={SpinnerSize.large} label="กำลังดำเนินการ..." labelPosition="right"*/ styles={spinnerStyles} />

                    }
                  </Stack>


                </Stack>
              </div>
            )}
            <br />
            <br />
            <br />

          </Stack>
        </Stack>


        {isLoadingGetNewData && (
          <Stack>
            <br />
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <div className="certificates-loading-center">
                <Spinner
                  label="กำลังโหลด..."
                  labelPosition="down"
                  styles={spinnerStylesLoading}
                />
              </div>
              <br />
            </Stack>
          </Stack>
        )}
      </div>
    </>
  );

}

export default SettingsCompaniesDetails;